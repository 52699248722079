import React, { useState, useEffect, useCallback } from "react";
import RangeOne from "../range-slider";
import Dropdown from "react-dropdown";
import PropTypes from "prop-types";
import SelectLocation from "../dropdown";
import domain from "../../api/constent";
import { debounce } from "../../utils/debounce";
import { CelebrateAlert, sweetAlert } from "../../utils/sweetAlert";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import {
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  Placeholder as RSPlaceholder,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Button as RSButton,
  Divider as RSDivider,
  Input as RSInput,
  Placeholder,
  Form as RSForm,
} from "rsuite";

import CompSelectSearch from "../../Employer/components/Forms/rsuite/CompSelectSearch";

import CircleProgess from "../progressBar";
import STYLES from "../../assets/css/styles.css";

import ReviewSubmit from "../../components/reviewSubmit/ReviewSubmit";
import apiService from "../../api/apiService";
import { FcOrganization, FcConferenceCall, FcHome } from "react-icons/fc";
import Spinner from "../../Employer/views/Spinner/Spinner";

import CardComp from "../../components/card-style/cardComp";
import RoleComp from "../../components/Role/RoleComp";
import ProfilePage from "../../pages/Profile/ProfilePage";
import axios from "../../api/axios";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";

import SearchAutoComplete from "../custom/SearchAutoComplete";
const select1 = [
  { value: "s1", label: "Design & Creative " },
  { value: "s2", label: "Design" },
  { value: "s3  ", label: "Ux/Ui" },
];
const select2 = [
  { value: "s1", label: "10K" },
  { value: "s2", label: "30k" },
];
const select3 = [
  { value: "s1", label: "<1 year experience" },
  { value: "s2", label: ">2 year experience" },
];
const select4 = [
  { value: "s1", label: "Certificate" },
  { value: "s2", label: ">Certificate 2" },
];
const select5 = [
  { value: "s1", label: "Marketing" },
  { value: "s2", label: "Developer" },
];
const select6 = [
  { value: "s1", label: "Weekly" },
  { value: "s2", label: "Weekly 2" },
];

// SidebarFilter.propTypes = {};

SidebarFilter.propTypes = {
  onUpdateValue: PropTypes.func,
  // onUpdateJobStateValue: PropTypes.string,
  // onUpdateJobCategoryValue: PropTypes.string,

  // initialValues: PropTypes.shape({
  //   searchValue: PropTypes.string,
  //   selectedJobStatus: PropTypes.string,
  //   selectedCategory: PropTypes.string,
  // }).isRequired,
};

const debouncedOnItemsRendered = debounce((args) => {
  console.log("Rendered items:", args);
}, 200);

const handleJobLocationChange = (value) => {
  console.log("Selected value:", value);
};

const renderMenu = (menu) => {
  return <div style={{ maxHeight: 300, overflowY: "auto" }}>{menu}</div>;
};

function SidebarFilter(props) {
  const {
    onUpdateValue,
    onUpdateJobLocationValue,
    onUpdateJobTypeFilterValue,
    onUpdateSalaryTypeFilterValue,
    onUpdateSalaryValueFilterValue,
    onUpdateMinExperienceFilterValue,
    onUpdateMaxExperienceFilterValue,
    onUpdateQualificationFilterValue,
  } = props; //onUpdateJobStateValue, onUpdateJobCategoryValue

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [getJobType, setJobType] = useState([]);
  const [getSalaryType, setSalaryType] = useState("");
  const [getSalaryValue, setSalaryValue] = useState("");
  const [getMinExperience, setMinExperience] = useState("");
  const [getMaxExperience, setMaxExperience] = useState("");

  const [getQualification, setQualification] = useState([]);

  const [searchList, setSearchList] = useState([]);
  const [getWherePlaceSearchingList, setWherePlaceSearchingList] = useState([]);
  const [employeeTypes, setEmploymentList] = useState([]);
  const [salaryTypeList, setSalaryTypeList] = useState([]);
  const [salaryRangeList, setSalaryRangeList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);

  const fetchFilteringData = async () => {
    // setLoading(true);
    try {
      // Make all API calls concurrently
      const [
        employmentData,
        salaryTypeData,
        salaryRangeData,
        experienceData,
        qualificationData,
        // listingAnyTimeList,
      ] = await Promise.all([
        apiService.OpenEmploymentType(),
        apiService.OpenSalaryTypeList(),
        apiService.OpenSalaryRangeList(),
        apiService.GetWorkExpList(),
        apiService.GetEducationQualificationList(),
        // apiService.OpenListingAnyTimeList(),
      ]);
      // console.log(`Log ${employmentData}`);

      // Update state with fetched data
      setEmploymentList(employmentData);
      setSalaryTypeList(salaryTypeData);
      setSalaryRangeList(salaryRangeData);
      setExperienceList(experienceData);
      setQualificationList(qualificationData);
      // setListingAnyTimeList(listingAnyTimeList);
    } catch (err) {
      console.error("Error fetching data:", err);
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };
  const fetchEnterKeywordList = async () => {
    try {
      const response = await axios.get(domain.Job_Keyword_Search_List);
      setSearchList(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    onUpdateValue(search);
    onUpdateJobLocationValue(jobLocation);
    onUpdateJobTypeFilterValue(getJobType);
    onUpdateSalaryTypeFilterValue(getSalaryType);
    onUpdateSalaryValueFilterValue(getSalaryValue);
    onUpdateMinExperienceFilterValue(getMinExperience);
    onUpdateMaxExperienceFilterValue(getMaxExperience);
    onUpdateQualificationFilterValue(getQualification);

    loadMore();
    fetchEnterKeywordList();
    fetchFilteringData();
    // Notify parent component when answers change
    // onUpdateJobStateValue(jobStatus);
    // onUpdateJobCategoryValue(category);
    // , jobStatus, category
  }, [
    search,
    jobLocation,
    getJobType,
    getSalaryType,
    getSalaryValue,
    getMinExperience,
    getMaxExperience,
    getQualification,
  ]);

  // const handleSearchChange = (e) => {
  //   console.log(e.target.value);
  //   // setSearch(e.value);
  //   // onUpdateValue(e.target.value);
  // };

  const handleJobLocationChange = (value) => {
    setJobLocation(value);
  };

  // const handleCategoryChange = (value) => {
  //   setJobType(value);
  // };

  const fetchWherePlaceSearchingM = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(domain.Job_Select_Where_Place_List);
      // console.log(response);

      setWherePlaceSearchingList(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    // finally {
    //   // setLoading(false);
    // }
  };
  const loadMore = () => {
    setLoading(true);
    setTimeout(() => {
      fetchWherePlaceSearchingM();
      setLoading(false);
    }, 1000);
  };
  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {/* {loading && <FixedLoader />} */}
      </>
    );
  };
  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= getWherePlaceSearchingList.length - 1) {
      loadMore();
    }
  };

  return (
    <div className="content-left style2 po-sticky">
      <div className="inner st-filter">
        <div className="widget-filter no-scroll mb1">
          {/* <form> */}
          <div className="group-form">
            <label className="title">Search by Keywords</label>
            <div
              className=" "
              style={{ backgroundColor: "white", width: "360" }}
            >
              <RSInput
                // className="standard-input"
                // data={searchList}
                style={{ width: 360, height: 45, color: "black" }}
                placeholder="Enter keyword"
                // value={search}
                onChange={(e) => {
                  // console.log(e);
                  setSearch(e);
                }}
                // onClickHandler={(e) => {
                //   console.log("click", e.target.value);
                // }}
              />
            </div>
            {/* <div className="group-input search-ip">
                <button>
                  <i className="icon-search"></i>
                </button>
                <input
                  type="text"
                  placeholder="keyword"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div> */}
          </div>
          <div className="group-form">
            <label className="title">Location</label>
            <div className="group-input has-icon">
              {/* <i className="icon-map-pin"></i> */}
              {/* <RSForm.Group controlId="search-city-region">
                  <RSForm.ControlLabel>Where</RSForm.ControlLabel>
                  <br /> */}
              <RSSelectPicker
                data={getWherePlaceSearchingList}
                style={{ width: 360, fontSize: "18px", lineHeight: "25px" }}
                // className="react-dropdown "
                virtualized
                onChange={(value) => {
                  setJobLocation(value);
                }}
                // renderMenu={renderMenu}
                // listProps={{
                //   onItemsRendered: debouncedOnItemsRendered,
                // }}
                value={jobLocation}
                placeholder="Enter Suburb, city, or region"
              />
              {/* </RSForm.Group> */}
            </div>
          </div>
          {/* <div className="group-form">
              <label className="title">Job Title</label>
              <div className="group-input">
                <input
                  type="text"
                  placeholder="keyword"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
          {/* <div className="group-form st2">
              <RangeOne miles="Years old" title="Age:" />
            </div> */}
          <div className="group-form">
            <label className="title">Job Type</label>

            <RSCheckPicker
              placeholder="Job Type"
              data={employeeTypes}
              onChange={(e) => {
                setJobType(e);
                // setEmploymentTypeFilter(e);
                // fetchInfo(currentPage);
                console.log(e);
              }}
              style={{ width: 360, background: "white" }}
            />
          </div>
          <div className="group-form">
            <label className="title">Salary Type</label>
            <RSSelectPicker
              placeholder="Salary Type"
              style={{ width: 360 }}
              data={salaryTypeList}
              onChange={(e) => {
                setSalaryType(e);
                // setSalaryTypeFilter(e);
              }}
            />
          </div>
          <div className="group-form">
            <label className="title">Salary </label>
            <RSSelectPicker
              placeholder="Please Select Salary Value "
              data={salaryRangeList}
              onChange={(e) => {
                setSalaryValue(e);
                // setSalaryRangeMinFilter(e);
                console.log(e);
              }}
              style={{ width: 360 }}
            />
          </div>
          <div className="group-form">
            <label className="title">Min Experience</label>
            <RSSelectPicker
              placeholder="Please Select Salary Value "
              data={experienceList}
              onChange={(e) => {
                setMinExperience(e);
                // setSalaryRangeMinFilter(e);
                console.log(e);
              }}
              style={{ width: 360 }}
            />
            {/* <RSTextField
                name="minYear"
                required={true}
                label="Min Years"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={experienceList}
                  />
                )}
              /> */}
          </div>{" "}
          <div className="group-form">
            <label className="title">Max Experience</label>
            <RSSelectPicker
              placeholder="Please Select Salary Value "
              data={experienceList}
              onChange={(e) => {
                setMaxExperience(e);
                // setSalaryRangeMinFilter(e);
                console.log(e);
              }}
              style={{ width: 360 }}
            />
            {/* <RSTextField
                name="minYear"
                required={true}
                label="Min Years"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={experienceList}
                  />
                )}
              /> */}
          </div>
          <div className="group-form">
            <label className="title">Qualification </label>
            <RSCheckTreePicker
              // value={value}
              onChange={(val) => {
                // Explicitly handle value change
                setQualification(val);
              }}
              searchable
              valueKey="id"
              labelKey="label"
              data={qualificationList}
              defaultExpandAll
              cascade
              placeholder="Select Education Qualification"
              style={{ width: 360 }}
            />
          </div>
          {/* <button type="submit">Find Candidates</button>
          </form> */}
        </div>
        {/* <div className="widget-filter no-scroll">
          <form>
            <div className="group-form">
              <label className="title">Title </label>
              <div className="group-input">
                <Dropdown
                  options={select5}
                  className="react-dropdown select2"
                  value={select5[0]}
                />
              </div>
            </div>
            <div className="group-form">
              <label className="title">Email Frequency</label>
              <div className="group-input">
                <Dropdown
                  options={select6}
                  className="react-dropdown select2"
                  value={select6[0]}
                />
              </div>
            </div>
            <button type="submit">save job Alert</button>
          </form>
        </div> */}
      </div>
    </div>
  );
}

export default SidebarFilter;
