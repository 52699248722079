import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormGroup,
} from "@mui/material";

function QuestionList({ getQuest, onUpdateValue, onValidation, errors,answerMount }) {
  const [answers, setAnswers] = useState(answerMount); // Define setAnswers here to manage answers state
  const [localErrors, setLocalErrors] = useState(errors || {}); // Store validation errors

  // Sync the local errors with parent errors when they change
  useEffect(() => {
    onUpdateValue(answers); // Notify parent component when answers change
    onValidation(localErrors); // Pass errors to parent when they change
  }, [answers, localErrors, onUpdateValue, onValidation]);

  // Handle change for single-choice, multiple-choice, or short-answer
  const handleChange = (questionId, value, isMultipleChoice, isShortAnswer) => {
    setLocalErrors((prev) => ({ ...prev, [questionId]: false })); // Clear errors on change

    if (isShortAnswer) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: value,
      }));
    } else if (isMultipleChoice) {
      setAnswers((prevAnswers) => {
        const newAnswers = { ...prevAnswers };
        if (newAnswers[questionId]) {
          if (newAnswers[questionId].includes(value)) {
            newAnswers[questionId] = newAnswers[questionId].filter(
              (item) => item !== value
            );
          } else {
            newAnswers[questionId] = [...newAnswers[questionId], value];
          }
        } else {
          newAnswers[questionId] = [value];
        }
        return newAnswers;
      });
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: value,
      }));
    }
  };

  // Validate answers before submission
  const validateAnswers = () => {
    const newErrors = {};
    let isValid = true;

    getQuest.forEach((question) => {
      if (question.mandatory === 1) {
        const answer = answers[question.id];
        if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          newErrors[question.id] = "This field is required.";
          isValid = false;
        }
      }
    });

    setLocalErrors(newErrors); // Update local errors
    return isValid; // Return validation status (true or false)
  };

  return (
    <Box>
      {getQuest.map((question, index) => {
        const isMultipleChoice = question.type === "multiple";
        const isShortAnswer = question.type === "short";

        return (
          <Card
            key={index}
            className="mt-3"
            style={{
              margin: "10px 0",
              border: localErrors[question.id] ? "2px solid red" : "none",
            }}
          >
            <CardContent>
              <Typography variant="h6">Question {index + 1}</Typography>
              <Typography variant="body1">
                {question.content}{" "}
                {question.mandatory === 1 && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </Typography>

              {isMultipleChoice ? (
                <FormControl component="fieldset">
                  <FormGroup>
                    {question.options.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={answers[question.id]?.includes(option) || false}
                            onChange={() => {
                              handleChange(question.id, option, true, false);
                            }}
                            value={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              ) : isShortAnswer ? (
                <TextField
                  value={answers[question.id] || ""}
                  onChange={(e) =>
                    handleChange(question.id, e.target.value, false, true)
                  }
                  fullWidth
                  placeholder="Type your answer here..."
                  variant="outlined"
                  margin="normal"
                  error={!!localErrors[question.id]}
                  helperText={localErrors[question.id] && "This field is required."}
                />
              ) : (
                <RadioGroup
                  value={answers[question.id] || ""}
                  onChange={(e) =>
                    handleChange(question.id, e.target.value, false, false)
                  }
                >
                  {question.options.map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
              )}
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
}

export default QuestionList;
