// import { Class } from "@mui/icons-material";

import React, {
  Component,
  Suspense,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import { FileDownload } from "@rsuite/icons";

import CircleProgess from "../../../../components/progressBar";

import Session from "../../../../api/session";
import PropTypes from "prop-types";
import Sidebar from "../../../../components/candidates/Sidebar";
import { Link, useHistory, useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SortBuy from "../../../../components/dropdown/SortBuy";
import domain from "../../../../api/constent";
import { sweetAlert } from "../../../../utils/sweetAlert";
import ArowBackIcon from "@rsuite/icons/ArowBack";

import STYLES from "../../../../assets/css/styles.css";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import SearchIcon from "@rsuite/icons/Search";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import PlusIcon from "@rsuite/icons/Plus";
import { Edit, Trash, Eye } from "@rsuite/icons";
import axios from "axios";
import ReviewSubmit from "../../../../components/reviewSubmit/ReviewSubmit";
import apiService from "../../../../api/apiService";

import { ReloadIcon } from "@rsuite/icons";
import LoaderMount from "../../../../components/preloader";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import { FaBriefcase, FaLocationDot, FaTag } from "react-icons/fa6";

import {
  IconButton as RSIconButton,
  ButtonToolbar as RSButtonToolbar,
  Badge as RSBadge,
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Tabs as RSTabs,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder as RSPlaceholder,
  Form as RSForm,
} from "rsuite";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import SidebarFilter from "../../../../components/candidates/SidebarFilter";

// ManageJo
const ManageCardPandingStyle = (props) => {
  const callingUpdateStatus = async (valueID, status) => {
    const response = await apiService.getEManageJobCandidateUpdateStatus({
      p_id: valueID,
      apply_status: parseInt(status),
    });
    if (response.data) {
      sweetAlert("success", response.data, 1);
      // fetchInfo(currentPage);
    } else {
      sweetAlert("failed", response.data, 2);
    }
    // console.log("SDF", response);
  };
  const previewFile = async (fileUrl, fileName) => {
    try {
      const apiUrl = `${process.env.REACT_APP_DB_URL}/click_download`;
      const params = {
        path: fileUrl,
        filename: fileName,
      };

      const response = await axios.get(apiUrl, {
        params,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        // window.open(
        //   `${process.env.REACT_APP_ROOT_URL}${url}`,
        //   "_blank",
        //   "noopener,noreferrer"
        // );
        //directly view
        window.open(url, "_blank");
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 60000);

        // sweetAlert("success", "File View successfully", 1);

        console.log("File downloaded successfully" + url);
      } else {
        console.error("Failed to download file");
        sweetAlert("warning", "Failed to download file", 2);
      }
    } catch (error) {
      console.error(
        "Error downloading file:",
        error.response?.data || error.message
      );
      sweetAlert("warning", "File is incorrect uploading ", 2);
    }
  };
  const downloadFile = async (fileUrl, fileName) => {
    // console.log(fileUrl);
    try {
      const apiUrl = `${process.env.REACT_APP_DB_URL}/click_download`;
      const params = {
        path: fileUrl,
        filename: fileName,
      };

      const response = await axios.get(apiUrl, {
        params,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        //directly download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        console.log("File downloaded successfully");
        sweetAlert("success", "File downloaded successfully", 1);
      } else {
        console.error("Failed to download file");
        sweetAlert("warning", "Failed to download file", 2);
      }
    } catch (error) {
      console.error(
        "Error downloading file:",
        error.response?.data || error.message
      );
      sweetAlert("warning", "File is incorrect uploading ", 2);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirect = () => {
    console.log("passing value", props);
    const objectPassing = {
      dataPass: props?.pendingList,
      jobAppliedDetails: props?.jobPostingDetail,
    };
    navigate("/manage-candidate-preview", { state: objectPassing });
  };
  return (
    <div
      key={props.pendingList.index}
      onClick={handleRedirect}
      className="features-job candidate-layout wd-thum-career"
    >
      {/* <p>
          {props.pendingList.id} {props.pendingList.apply_status_code}
        </p> */}

      <div className="job-archive-header">
        <div className="career-header-left">
          <img
            src={`${process.env.REACT_APP_ROOT_URL}${props.pendingList.candidate_profile_mid_path}${props.pendingList.candidate_details.profile_img} `}
            alt="job-applied-candate"
            className="thumb"
          />
          <div className="career-left-inner">
            <h3>
              <b>
                {" "}
                {props.pendingList.candidate_details.first_name}{" "}
                {props.pendingList.candidate_details.last_name}
              </b>
              <br />
              <small style={{ fontSize: "14px" }}>
                <FaBriefcase />
                &nbsp;
                {props.pendingList.total_experience}&nbsp;&nbsp;&nbsp;
                <FaTag />
                Exp. salary:&nbsp;
                {props.pendingList.expected_salary}&nbsp;&nbsp;&nbsp;
                <FaLocationDot />
                {props.pendingList.candidate_location}
              </small>
              <ul className="author-list">
                <small
                  className=""
                  style={{
                    color: "gray",
                    fontSize: "13px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Current
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </small>{" "}
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: "13px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.pendingList.candidate_working_carrer_list.map(
                    (value, indx) => {
                      return (
                        <>
                          {value.is_current != 0 ? (
                            <span key={indx} className="tag">
                              {" "}
                              {value.job_title} at {value.company_name}&nbsp;
                            </span>
                          ) : null}
                        </>
                      );
                    }
                  )}
                </span>
              </ul>{" "}
              <ul
                className="author-list m-0 p-0"
                style={{ listStyleType: "none" }}
              >
                <li className="d-flex align-items-center">
                  <small
                    // className="p-0"
                    style={{
                      color: "gray",
                      fontSize: "13px",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                      paddingBottom: "30px",
                    }}
                  >
                    Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </small>
                  <span
                    style={{
                      color: "black",
                      fontSize: "13px",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "column", // Change to column layout
                      gap: "5px",
                    }}
                  >
                    {props.pendingList.candidate_education_list.map(
                      (value, indx) => (
                        <span
                          key={indx}
                          className="tag"
                          style={{ display: "block", lineHeight: "10px" }}
                        >
                          {value.course} {value.institution}{" "}
                          {value.finished_year} ,
                        </span>
                      )
                    )}
                  </span>
                </li>
              </ul>
              <ul className="author-list">
                <small
                  className=""
                  style={{
                    color: "gray",
                    fontSize: "13px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Pref. locations&nbsp;&nbsp;
                </small>{" "}
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: "13px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>{props.pendingList.candidate_pref_location}</span>
                </span>
              </ul>{" "}
              <ul className="author-list">
                <small
                  className=""
                  style={{
                    color: "gray",
                    fontSize: "13px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Key skills
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </small>{" "}
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: "13px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.pendingList.candidate_skill_type.map((value, indx) => {
                    return (
                      <span key={indx} className="tag">
                        {" "}
                        {value.label},&nbsp;
                      </span>
                    );
                  })}
                </span>
              </ul>{" "}
              {/* {
                <RSList>
                  <ul className="tags">
                    {props.pendingList.candidate_job_type.length > 0 ? (
                      props.pendingList.candidate_job_type.map(
                        (data, index) => (
                          <li key={index}>
                            <strong to="#">
                              {data.label}&nbsp;{""}
                            </strong>
                          </li>
                        )
                      )
                    ) : (
                      <li>
                        <p to="#">No Employment available</p>
                      </li>
                    )}
                  </ul>
                </RSList>
              } */}
            </h3>
          </div>
        </div>
        <div
          className="career-header-right"
          style={{
            display: "d-flex",
            textAlign: "end",
            // padding: "2px",
          }}
        >
          <RSBadge
            color={props.pendingList.down_resume !== "" ? "green" : "red"}
          >
            {/* content={999} */}
            <RSButton
              className="me-2"
              appearance="ghost"
              onClick={() => {
                // previewFile(
                //   `${props.pendingList.previ_resume}`,
                //   "resume"
                // )
                if (props.pendingList.previ_resume != "") {
                  window.open(
                    `${process.env.REACT_APP_ROOT_URL}${props.pendingList.previ_resume}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                } else {
                  sweetAlert("warning", "Failed to download file", 2);
                }
              }}
            >
              <b>View CV</b>
            </RSButton>
            <RSIconButton
              icon={<FileDownload />}
              appearance="ghost"
              onClick={() => {
                downloadFile(`${props.pendingList.down_resume}`, "resume");
              }}
            >
              {/* Download */}
            </RSIconButton>
          </RSBadge>
          &nbsp;&nbsp;&nbsp;
          <RSBadge
            className="mt-2"
            color={props.pendingList.down_cover_letter !== "" ? "green" : "red"}
          >
            <RSButton
              appearance="ghost"
              className="me-2"
              onClick={() => {
                if (props.pendingList.previ_cover_letter != "") {
                  window.open(
                    `${process.env.REACT_APP_ROOT_URL}${props.pendingList.previ_cover_letter}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                } else {
                  sweetAlert("warning", "Failed to download file", 2);
                }
                // previewFile(
                //   `${props.pendingList.previ_cover_letter}`,
                //   "cover_letter"
                // );
              }}
            >
              <b>View Cover Letter</b>
            </RSButton>
            <RSIconButton
              icon={<FileDownload />}
              appearance="ghost"
              onClick={() => {
                downloadFile(
                  `${props.pendingList.down_cover_letter}`,
                  "cover_letter"
                );
              }}
            >
              {/* Download */}
            </RSIconButton>
          </RSBadge>{" "}
        </div>
      </div>
      <div className="job-archive-footer">
        <RSButtonToolbar>
          <RSIconButton
            appearance={
              // jobManagePostingList.some(
              //   (c) => c.status_mount !== status
              // )
              //   ? "primary"
              // :
              "ghost"
            }
            color={
              // jobManagePostingList.some(
              //   (c) => c.status_mount !== status
              // )
              //   ? "green"
              //   :
              "green"
            }
            onClick={() => {
              callingUpdateStatus(props.pendingList.id, 1);
              // handleShortlist(props.pendingList.id);
            }}
            icon={<CheckOutlineIcon />}
          >
            {/* {jobManagePostingList.some(
                (c) => c.id !== status
              )
                ? "Shortlisted" */}
            {/* :  */}
            Shortlist
            {/* } */}
          </RSIconButton>{" "}
          <RSIconButton
            color="red"
            appearance="ghost"
            onClick={() => {
              callingUpdateStatus(props.pendingList.id, 2);
              // handleShortlist(props.pendingList.id);
            }}
            // onClick={() => handleReject(props.pendingList.id)} // This can also be used to remove
            icon={<CloseOutlineIcon />}
          >
            Reject
          </RSIconButton>
        </RSButtonToolbar>

        {/* <Link to="" className="tf-btn">
        Profile
      </Link> */}

        <div className="carrer-footer-right">
          <b>
            Applied Job {props.pendingList.apply_date} |{" "}
            {props.pendingList.apply_time}
          </b>
          {/* <span className="icon-dolar1"></span>
    <p>${props.pendingList.price}/month</p> */}
        </div>
      </div>
    </div>
    // <TabPanel className="inner">
    //             <div className="group-col-2">
    //               {jobManagePostingList.map((idx) => (
    //                 <div
    //                   key={idx.id}
    //                   className="features-job wd-thum-career style-2 cl2 mb1"
    //                 >
    //                   <div className="job-archive-header">
    //                     <b>
    //                       Applied Job {idx.apply_date} | {idx.apply_time}
    //                     </b>
    //                     <div className="career-header-left">
    //                       <img
    //                         src={`${process.env.REACT_APP_ROOT_URL}${idx.candidate_profile_mid_path}${idx.candidate_details.profile_img} `}
    //                         alt="job-applied-candate"
    //                         className="thumb"
    //                       />{" "}
    //                       <div className="career-left-inner">
    //                         <h3>
    //                           <Link>
    //                             {" "}
    //                             {idx.candidate_details.first_name}{" "}
    //                             {idx.candidate_details.last_name}
    //                           </Link>

    //                           <br />
    //                           <span className="icon-map-pin"></span>
    //                           <small>
    //                             {idx.candidate_details.home_location}
    //                           </small>
    //                           <small>
    //                             {idx.candidate_details.current_location}
    //                           </small>
    //                         </h3>
    //                         <h3>
    //                           <Link>{idx.home_location}</Link>
    //                           &nbsp;
    //                         </h3>

    //                         <ul className="career-tag">
    //                           <li>
    //                             <Link to="#">Blender</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="#">Sketch</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="#">Adobe XD</Link>
    //                           </li>
    //                           <li>
    //                             <Link to="#">Figma</Link>
    //                           </li>
    //                         </ul>
    //                       </div>
    //                     </div>
    //                     <div className="career-header-right">
    //                       <span className="icon-heart"></span>
    //                     </div>
    //                   </div>
    //                   <div className="job-archive-footer">
    //                     <Link to="/Candidatesingle_v1" className="tf-btn">
    //                       View Profile
    //                     </Link>
    //                   </div>
    //                 </div>
    //               ))}
    //             </div>
  );
};
export default ManageCardPandingStyle;
