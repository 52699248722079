import React from "react";
import { getApp, getAppUrl } from "./utils/helpers";
import AppCandidate from "./layouts/AppCandidate";
import AppEmployer from "./layouts/AppEmployer";
import Page404 from "./pages/Page404";
const App = () => {
  const pathname = getAppUrl();
  console.log("App Type Extracted:", pathname);

  if (pathname == 2) {
    console.log("AppEmployer  App");

    return <AppEmployer />;
  } else {
    console.log("AppCandidate App");
    return <AppCandidate />;
  }
};

export default App;
