import React, {
  Suspense,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import { FileDownload } from "@rsuite/icons";

import CircleProgess from "../../../../components/progressBar";

import Session from "../../../../api/session";
import PropTypes from "prop-types";
import Sidebar from "../../../../components/candidates/Sidebar";
import { Link, useHistory, useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SortBuy from "../../../../components/dropdown/SortBuy";
import domain from "../../../../api/constent";
import { sweetAlert } from "../../../../utils/sweetAlert";
import ArowBackIcon from "@rsuite/icons/ArowBack";

import STYLES from "../../../../assets/css/styles.css";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import SearchIcon from "@rsuite/icons/Search";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import PlusIcon from "@rsuite/icons/Plus";
import { Edit, Trash, Eye } from "@rsuite/icons";
import axios from "axios";
import ReviewSubmit from "../../../../components/reviewSubmit/ReviewSubmit";
import apiService from "../../../../api/apiService";

import { ReloadIcon } from "@rsuite/icons";
import LoaderMount from "../../../../components/preloader";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import {
  IconButton as RSIconButton,
  ButtonToolbar as RSButtonToolbar,
  Badge as RSBadge,
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Tabs as RSTabs,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder as RSPlaceholder,
  Form as RSForm,
} from "rsuite";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import SidebarFilter from "../../../../components/candidates/SidebarFilter";
import ManageCardPandingStyle from "./ManageCardPandingStyle";
import ManageCardShortedStyle from "./ManageCardShortedStyle";
import ManageCardRejectStyle from "./ManageCardRejectStyle";

// ManageJobCandidateList.candidates = {};

function ManageJobCandidateList() {
  const location = useLocation();
  const navigate = useNavigate();
  const getParticularJobPostData = location.state?.idx;
  // console.log("Logger ON");

  // console.log(getParticularJobPostData);

  const AllList = location.state;

  const [jobManagePostingList, setManageJobPostingList] = useState([]);

  const [perPagefil, setPerPageFil] = useState(10);
  const [perSortList, setPerSortList] = useState("asc");

  // filter
  const [searchValue, setSearchValue] = useState("");
  const [selectAppliedLocation, setSelectAppliedLocation] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState([]);
  const [salaryType, setSalaryTypeFilter] = useState(0);
  const [salaryValue, setSalaryValueFilter] = useState(0);
  const [experienceValueMin, setMinExperienceFilter] = useState(null);
  const [experienceValueMax, setMaxExperienceFilter] = useState(null);
  const [qualificationValue, setQualificationFilter] = useState([]);

  // passing Layout list data
  const [pandingStatus, setPandingStatus] = useState(0);
  const [shortStatus, setShorStatus] = useState(0);
  const [rejectStatus, setRejectStatus] = useState(0);

  // p a r
  const [status, setStatusMount] = useState(0);

  const [loading, setLoading] = useState(false);
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  //total Jobs
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchInfo(currentPage);
    // console.log(jobManagePostingList);
    // setActionPendingCount(totalPages - shortlisted.length);
    // setShortlistedCount(shortlisted.length);
    // setRejectedCount(rejected.length);
    // setCandidates(jobManagePostingList);
    // setStatusMount(status);
    // console.log(status);
  }, [
    currentPage,
    status,
    perPagefil,
    perSortList,
    searchValue,
    selectAppliedLocation,
    jobTypeFilter,
    salaryType,
    salaryValue,
    experienceValueMin,
    experienceValueMax,
    qualificationValue,
    // shortlisted,
    // candidates,
    // rejected,
    // shortlistedCount,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchInfo(page);
  };
  const fetchInfo = async (page) => {
    // console.log(
    //   "enter keyword : ",
    //   searchValue,
    //   "location : ",

    //   selectAppliedLocation,
    //   "job type : ",

    //   jobTypeFilter,
    //   "salary type : ",

    //   salaryType,
    //   "salary value : ",

    //   salaryValue,
    //   "min Value : ",

    //   experienceValueMin,
    //   "max value : ",

    //   experienceValueMax,
    //   "qualification value: ",

    //   qualificationValue
    // );

    setLoading(true);
    const sessionData = Session.getDataEmployerSessionStore();
    const downPoint = {
      find_job_id: getParticularJobPostData.id, //sessionData && sessionData.id ? sessionData.id : 0,
      //   encrypted:
      //     sessionData && sessionData.encryptId ? sessionData.encryptId : "0",
      page: page,
      per_page: perPagefil, //itemsPerPage ??
      order_direction: perSortList, //sortValue,
      //   candidate_info: sessionData && sessionData.id ? sessionData.id : 0,
      keyword: searchValue,
      status_code: status,
      //   role_id: listFind2,
      place_search: selectAppliedLocation,
      emp_type: JSON.stringify(jobTypeFilter),
      salary_type: salaryType,
      salary_value: salaryValue,
      min_experience: experienceValueMin,
      max_experience: experienceValueMax,
      education_list: JSON.stringify(qualificationValue),
    };
    //   salary_range_min: parseInt(salaryRangeMinFilter, 10) ?? 0,
    //   salary_range_max: parseInt(salaryRangeMaxFilter, 10) ?? 0,
    //   listing_time: listingTimeList,

    const response = await apiService.getEAppliedJobCandidateList(downPoint);
    console.log(response);
    if (response) {
      const { data } = response;
      setCurrentPage(data.current_page);
      setLastPage(data.last_page);
      setItemsPerPage(data.per_page);
      setTotalPages(data.total);
      setManageJobPostingList(data.data);

      setPandingStatus(data.pending_status);
      setShorStatus(data.short_status);
      setRejectStatus(data.reject_status);

      setLoading(false);
    }
    if (!response) return;
  };

  const [currentComponent, setCurrentComponent] = useState("list");
  const [filteredJobs, setFilteredJobs] = useState([]);
  // const handleClick = (idx) => {
  //   // Filter jobs and update state
  //   const jobs = jobManagePostingList.filter((job) => job.id === idx.id);
  //   setFilteredJobs(jobs);
  //   setCurrentComponent("candidates"); // Switch to the candidate list
  // };

  // const previewFile = async (fileUrl, fileName) => {
  //   try {
  //     const apiUrl = `${process.env.REACT_APP_DB_URL}/click_download`;
  //     const params = {
  //       path: fileUrl,
  //       filename: fileName,
  //     };

  //     const response = await axios.get(apiUrl, {
  //       params,
  //       responseType: "blob",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (response.status === 200) {
  //       const blob = new Blob([response.data], { type: "application/pdf" });
  //       const url = window.URL.createObjectURL(blob);
  //       // window.open(
  //       //   `${process.env.REACT_APP_ROOT_URL}${url}`,
  //       //   "_blank",
  //       //   "noopener,noreferrer"
  //       // );
  //       //directly view
  //       window.open(url, "_blank");
  //       setTimeout(() => {
  //         window.URL.revokeObjectURL(url);
  //       }, 60000);

  //       // sweetAlert("success", "File View successfully", 1);

  //       console.log("File downloaded successfully" + url);
  //     } else {
  //       console.error("Failed to download file");
  //       sweetAlert("warning", "Failed to download file", 2);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error downloading file:",
  //       error.response?.data || error.message
  //     );
  //     sweetAlert("warning", "File is incorrect uploading ", 2);
  //   }
  // };
  // const downloadFile = async (fileUrl, fileName) => {
  //   console.log(fileUrl);
  //   try {
  //     const apiUrl = `${process.env.REACT_APP_DB_URL}/click_download`;
  //     const params = {
  //       path: fileUrl,
  //       filename: fileName,
  //     };

  //     const response = await axios.get(apiUrl, {
  //       params,
  //       responseType: "blob",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (response.status === 200) {
  //       const blob = new Blob([response.data], { type: "application/pdf" });
  //       const url = window.URL.createObjectURL(blob);

  //       //directly download
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `${fileName}.pdf`);
  //       document.body.appendChild(link);
  //       link.click();

  //       // Clean up
  //       link.parentNode.removeChild(link);
  //       window.URL.revokeObjectURL(url);

  //       console.log("File downloaded successfully");
  //       sweetAlert("success", "File downloaded successfully", 1);
  //     } else {
  //       console.error("Failed to download file");
  //       sweetAlert("warning", "Failed to download file", 2);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error downloading file:",
  //       error.response?.data || error.message
  //     );
  //     sweetAlert("warning", "File is incorrect uploading ", 2);
  //   }
  //   // const link = document.createElement("a");
  //   // link.href =
  //   //   process.env.REACT_APP_DB_URL +
  //   //   `/click_download` +
  //   //   `?path=` +
  //   //   fileUrl +
  //   //   `&filename=` +
  //   //   fileName +
  //   //   ``;
  //   // link.setAttribute("download", "");
  //   // document.body.appendChild(link);

  //   // link.click();
  //   // document.body.removeChild(link);
  // };

  // const callingUpdateMount = (value) => {
  //   console.log(value);

  //   setStatusMount(value);
  // };
  // const callingUpdateStatus = async (valueID, status) => {
  //   const response = await apiService.getEManageJobCandidateUpdateStatus({
  //     p_id: valueID,
  //     apply_status: parseInt(status),
  //   });
  //   if (response.data) {
  //     sweetAlert("success", response.data, 1);
  //     fetchInfo(currentPage);
  //   } else {
  //     sweetAlert("failed", response.data, 2);
  //   }
  //   console.log("SDF", response);
  // };
  return (
    <section className="candidates-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-4 ctn2">
            <div className="p-4">
              <h4>Manage Jobs & Responses</h4>
            </div>
            <SidebarFilter
              onUpdateValue={setSearchValue} // Function to update searchValue
              onUpdateJobLocationValue={setSelectAppliedLocation}
              onUpdateJobTypeFilterValue={setJobTypeFilter}
              onUpdateSalaryTypeFilterValue={setSalaryTypeFilter}
              onUpdateSalaryValueFilterValue={setSalaryValueFilter}
              onUpdateMinExperienceFilterValue={setMinExperienceFilter}
              onUpdateMaxExperienceFilterValue={setMaxExperienceFilter}
              onUpdateQualificationFilterValue={setQualificationFilter}
            />
          </div>

          <Tabs className="col-lg-8 tf-tab ctn2">
            <div className="wd-meta-select-job">
              <div className="wd-findjob-filer">
                <div className="group-select-display">
                  <TabList className="inner menu-tab">
                    <Tab className="btn-display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M0.5 12.001L0.5 16.0005C0.880952 16.001 1.09693 16.001 1.83333 16.001L15.1667 16.001C15.9031 16.001 16.5 16.0005 16.5 16.0005L16.5 12.001C16.5 12.001 15.9031 12.001 15.1667 12.001L1.83333 12.001C1.09693 12.001 0.880952 12.001 0.5 12.001Z"
                          fill="#A0A0A0"
                        />
                        <path
                          d="M0.5 6.00098L0.5 10.0005C0.880952 10.001 1.09693 10.001 1.83333 10.001L15.1667 10.001C15.9031 10.001 16.5 10.0005 16.5 10.0005L16.5 6.00098C16.5 6.00098 15.9031 6.00098 15.1667 6.00098L1.83333 6.00098C1.09693 6.00098 0.880952 6.00098 0.5 6.00098Z"
                          fill="#A0A0A0"
                        />
                        <path
                          d="M0.5 0.000976562L0.5 4.0005C0.880952 4.00098 1.09693 4.00098 1.83333 4.00098L15.1667 4.00098C15.9031 4.00098 16.5 4.0005 16.5 4.0005L16.5 0.000975863C16.5 0.000975863 15.9031 0.000975889 15.1667 0.000975921L1.83333 0.000976504C1.09693 0.000976536 0.880952 0.000976546 0.5 0.000976562Z"
                          fill="#A0A0A0"
                        />
                      </svg>
                    </Tab>
                    {/* <Tab className="btn-display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M4.5 0H0.500478C0.5 0.380952 0.5 0.596931 0.5 1.33333V14.6667C0.5 15.4031 0.500478 16 0.500478 16H4.5C4.5 16 4.5 15.4031 4.5 14.6667V1.33333C4.5 0.596931 4.5 0.380952 4.5 0Z"
                          fill="white"
                        />
                        <path
                          d="M10.5 0H6.50048C6.5 0.380952 6.5 0.596931 6.5 1.33333V14.6667C6.5 15.4031 6.50048 16 6.50048 16H10.5C10.5 16 10.5 15.4031 10.5 14.6667V1.33333C10.5 0.596931 10.5 0.380952 10.5 0Z"
                          fill="white"
                        />
                        <path
                          d="M16.5 0H12.5005C12.5 0.380952 12.5 0.596931 12.5 1.33333V14.6667C12.5 15.4031 12.5005 16 12.5005 16H16.5C16.5 16 16.5 15.4031 16.5 14.6667V1.33333C16.5 0.596931 16.5 0.380952 16.5 0Z"
                          fill="white"
                        />
                      </svg>
                    </Tab> */}
                  </TabList>
                  <p className="nofi-job">
                    <span>{totalPages}</span> Total Applied Candidates
                  </p>
                </div>

                <SortBuy
                  mountSetPerPage={setPerPageFil}
                  mountSetSortList={setPerSortList}
                  prop={[
                    { value: "12", label: "12 Per Page" },
                    { value: "1", label: "1 Per Page" },
                    { value: "10", label: "10 Per Page" },
                    { value: "8", label: "8 Per Page" },
                  ]}
                  props={[
                    { value: "", label: "Sort by (Default)" },
                    { value: "asc", label: "Asc" },
                    { value: "desc", label: "Desc" },
                  ]}
                />
              </div>
            </div>
            <RSTabs
              defaultActiveKey="0"
              onSelect={(e) => {
                setStatusMount(parseInt(e));
                // callingUpdateMount(parseInt(e));
              }}
            >
              <RSTabs.Tab
                eventKey="0"
                title={
                  <b
                    onClick={() => {
                      setStatusMount(parseInt(0));
                    }}
                  >
                    Pending{" "}
                    <RSBadge
                      content={pandingStatus}
                      style={{ marginLeft: 5 }}
                    />
                  </b>
                }
              >
                <div className="content-tab no-scroll">
                  {loading ? (
                    <div
                      className="text-center"
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "100vh",
                      }}
                    >
                      <LoaderMount.CuLoader />
                    </div>
                  ) : (
                    <>
                      <TabPanel
                        className="inner"
                        // onClick={() => {
                        //   console.log("SDf");
                        // }}
                      >
                        {jobManagePostingList.map((idx, indx) => (
                          <ManageCardPandingStyle
                            pendingList={idx}
                            index={indx}
                            jobPostingDetail={getParticularJobPostData}
                          />
                        ))}
                      </TabPanel>
                    </>
                  )}
                </div>

                {/* <RSPlaceholder.Paragraph graph="image" /> */}
              </RSTabs.Tab>
              <RSTabs.Tab
                eventKey="1"
                title={
                  <b
                    onClick={() => {
                      setStatusMount(parseInt(1));
                    }}
                  >
                    Shortlisted{" "}
                    <RSBadge content={shortStatus} style={{ marginLeft: 5 }} />
                  </b>
                }
              >
                {loading ? (
                  <div
                    className="text-center"
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100vh",
                    }}
                  >
                    <LoaderMount.CuLoader />
                  </div>
                ) : (
                  <>
                    {jobManagePostingList.map((idx, indx) => (
                      <ManageCardShortedStyle
                        shortingList={idx}
                        index={indx}
                        jobPostingDetail={getParticularJobPostData}
                      />
                    ))}
                  </>
                )}

                {/* <RSPlaceholder.Paragraph graph="square" /> */}
              </RSTabs.Tab>
              <RSTabs.Tab
                eventKey="2"
                title={
                  <b
                    onClick={() => {
                      setStatusMount(parseInt(2));
                    }}
                  >
                    Rejected{" "}
                    <RSBadge content={rejectStatus} style={{ marginLeft: 5 }} />
                  </b>
                }
              >
                {loading ? (
                  <div
                    className="text-center"
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100vh",
                    }}
                  >
                    <LoaderMount.CuLoader />
                  </div>
                ) : (
                  <>
                    {jobManagePostingList.map((idx, indx) => (
                      <ManageCardRejectStyle
                        rejectList={idx}
                        index={indx}
                        jobPostingDetail={getParticularJobPostData}
                      />
                    ))}
                  </>
                )}
                {/* <RSPlaceholder.Paragraph graph="circle" /> */}
              </RSTabs.Tab>
            </RSTabs>
            <ul className="pagination-job p-top-st1">
              <RSPaginator
                prev
                next
                first
                last
                ellipsis
                size="lg"
                boundaryLinks
                pages={lastPage}
                activePage={currentPage}
                onSelect={(e) => {
                  handlePageChange(e);
                }}
              />
            </ul>
            <RSDivider />
          </Tabs>

          <RSDivider />
        </div>
      </div>
    </section>
  );
}

export default ManageJobCandidateList;
