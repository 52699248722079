// ManageCandidatePreview
// import react, { Component, useEffect } from "react";

// const ManageCandidatePreview = (props) => {

//   useEffect(() => {
//     console.log("HTEST",props);
//   }, []);

//   return <p>checking</p>;
// };

// export default ManageCandidatePreview;

import React from "react";
import PropTypes from "prop-types";
// import Footer from "../components/footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Progress from "../../../../components/progressBar/DonutProgress";
import Gotop from "../../../../components/gotop";
import { Link, useHistory, useNavigate, useLocation } from "react-router-dom";

import Video from "../../../../components/popup/Video";
import Gallery from "../../../../components/popup/Gallery";
import { Collapse } from "react-collapse";
import logo from "../../../../assets/images/logo.png";
// import Header4 from "../components/header/Header4";

import CloseOutlineIcon from "@rsuite/icons/CloseOutline";

import {
  IconButton as RSIconButton,
  ButtonToolbar as RSButtonToolbar,
  Badge as RSBadge,
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Tabs as RSTabs,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder as RSPlaceholder,
  Form as RSForm,
} from "rsuite";

import axios from "axios";

import { FileDownload } from "@rsuite/icons";

import domain from "../../../../api/constent";
import { sweetAlert } from "../../../../utils/sweetAlert";
import ArowBackIcon from "@rsuite/icons/ArowBack";

import STYLES from "../../../../assets/css/styles.css";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import AutoModeIcon from "@mui/icons-material/AutoMode";

ManageCandidatePreview.propTypes = {};

function ManageCandidatePreview(props) {
  const progressRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state?.dataPass;
  const JobAppliedDetail = location.state?.jobAppliedDetails;

  const [targetHeight, setTargetHeight] = useState(0);

  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };
  useEffect(() => {}, []);

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    console.log("HTEST", formData);
    console.log("applied", JobAppliedDetail);

    if (progressRef?.current) {
      const offsetHeight = progressRef?.current?.offsetTop;
      setTargetHeight(offsetHeight);
    }
  }, [progressRef]);

  const downloadFile = async (fileUrl, fileName) => {
    // console.log(fileUrl);
    try {
      const apiUrl = `${process.env.REACT_APP_DB_URL}/click_download`;
      const params = {
        path: fileUrl,
        filename: fileName,
      };

      const response = await axios.get(apiUrl, {
        params,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        //directly download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        console.log("File downloaded successfully");
        sweetAlert("success", "File downloaded successfully", 1);
      } else {
        console.error("Failed to download file");
        sweetAlert("warning", "Failed to download file", 2);
      }
    } catch (error) {
      console.error(
        "Error downloading file:",
        error.response?.data || error.message
      );
      sweetAlert("warning", "File is incorrect uploading ", 2);
    }
    // const link = document.createElement("a");
    // link.href =
    //   process.env.REACT_APP_DB_URL +
    //   `/click_download` +
    //   `?path=` +
    //   fileUrl +
    //   `&filename=` +
    //   fileName +
    //   ``;
    // link.setAttribute("download", "");
    // document.body.appendChild(link);

    // link.click();
    // document.body.removeChild(link);
  };
  const getRandomColor = () => {
    const rainbowColors = [
      "#FF5733",
      "#FFBD33",
      "#DBFF33",
      "#75FF33",
      "#33FF57",
      "#33FFBD",
      "#33DBFF",
      "#3375FF",
      "#5733FF",
      "#BD33FF",
    ];
    return rainbowColors[Math.floor(Math.random() * rainbowColors.length)];
  };
  const QuestionAnswersTimeline = ({ questions, answers }) => {
    return (
      <div>
        <h5>Questionnaire</h5>
        <div className="group-infor">
          {questions.map((question, index) => {
            // Find the user's answer based on the index
            const userAnswer = answers[index];

            return (
              <div key={question.id} className="inner">
                <div className="sub-heading">
                  {/* sub-heading */}
                  {/* sub-heading */}
                  {question.content}
                  {question.mandatory === 1 && (
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  )}
                  {/* <span>
                    {new Date(question.updated_at).toLocaleDateString()}
                  </span> */}
                </div>

                {question.options.length > 0 && (
                  <p>Options: {question.options.join(", ")}</p>
                )}
                <div className="heading">
                  {/* heading */}
                  answare:{" "}
                  {Array.isArray(userAnswer)
                    ? userAnswer.join(", ")
                    : userAnswer}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="wrapper-author-page-title style2 stc form-sticky fixed-space">
        <div className="tf-container">
          <div className="wd-author-page-title">
            <div className="author-archive-header">
              <img
                src={`${process.env.REACT_APP_ROOT_URL}${formData.candidate_profile_mid_path}${formData.candidate_details["profile_img"]} `}
                // src={require("../../../../assets/images/user/avatar/avt-author-1.jpg")}
                alt="profile image"
                className="logo-company"
              />
              <div className="content" style={{ paddingTop: "15px" }}>
                {formData.candidate_details['status'] != 0 ? (
                  <Link to="#" className="tag-head">
                    Available now
                  </Link>
                ) : null}
                <div className="d-flex">
                  <h5 style={{ fontWeight: "bold", marginBottom: 0 }}>
                    Title:&nbsp;{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {JobAppliedDetail["title"]}
                    </span>
                  </h5>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontWeight: "bold", marginBottom: 0 }}>
                    Name:&nbsp;{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formData.candidate_details["first_name"] +
                        " " +
                        formData.candidate_details["last_name"]}
                    </span>
                  </h5>
                </div>

                <ul className="author-list">
                  <h5>
                    <b> Key Skills: &nbsp;</b>
                  </h5>{" "}
                  {formData.candidate_skill_type.map((value, indx) => {
                    return (
                      <li key={indx} className="tag">
                        {" "}
                        {value.label}{" "}
                      </li>
                    );
                  })}
                  {/* <li className="tag"> Sketch </li>
                  <li className="tag"> Adobe XD </li>
                  <li className="tag"> Figma </li> */}
                  <li className="map">
                    <span className="icon-map-pin"></span>&nbsp;{" "}
                    {formData.candidate_location ?? ""}
                  </li>
                  {/* <li className="price">
                    <span className="icon-dolar1"></span>&nbsp; $300/day
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="author-archive-footer">
              {/* <button className="tf-btn btn-popup">DownLoad CV</button> */}
              <button
                className="tf-btn btn-author"
                onClick={() => {
                  // previewFile(
                  //   `${formData.previ_resume}`,
                  //   "resume"
                  // )
                  if (formData.previ_resume != "") {
                    window.open(
                      `${process.env.REACT_APP_ROOT_URL}${formData.previ_resume}`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  } else {
                    sweetAlert("warning", "Failed to download file", 2);
                  }
                }}
              >
                View Resume
              </button>

              <button
                onClick={() => {
                  if (formData.previ_cover_letter != "") {
                    window.open(
                      `${process.env.REACT_APP_ROOT_URL}${formData.previ_cover_letter}`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  } else {
                    sweetAlert("warning", "Failed to download file", 2);
                  }
                  // previewFile(
                  //   `${formData.previ_cover_letter}`,
                  //   "cover_letter"
                  // );
                }}
                className="tf-btn btn-author"
              >
                View Cover Letter
              </button>

              {/* <button className="tf-btn btn-author">Message</button> */}
            </div>
          </div>
        </div>
      </section>

      <section className="candidates-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8">
              <Tabs className="job-article stc tf-tab single-job">
                {/* <TabList className="menu-tab stc">
                  <Tab className="ct-tab">Resumes</Tab>
                  <Tab className="ct-tab">Portfolio</Tab>
                  <Tab className="ct-tab">Contact</Tab>
                </TabList> */}
                <div className="content-tab">
                  <TabPanel className="inner-content animation-tab">
                    <h5>Summary</h5>

                    <p className="mg-39">
                      {formData.candidate_details.summary}
                    </p>
                    <RSDivider />
                    <h5>Education</h5>
                    <div className="group-infor">
                      {formData.candidate_education_list.map((value, indx) => {
                        return (
                          <div className="inner">
                            <div className="sub-heading ">
                              {/* sub-heading */}
                              {value.institution}
                              {/* <span>2019 - 2021</span> */}
                            </div>
                            {/* heading */}
                            <div className="heading">{value.course}</div>
                            <p>{value.course_highlights}</p>
                          </div>
                        );
                      })}
                    </div>
                    <RSDivider />

                    <h5>Experience</h5>
                    <div className="group-infor">
                      {formData.candidate_working_carrer_list.map(
                        (value, indx) => {
                          return (
                            <div className="inner" key={indx}>
                              <div className="sub-heading">
                                {/* sub-heading */}
                                {value.company_name}
                                <span>
                                  &nbsp;&nbsp;
                                  {value.start_month}&nbsp;/&nbsp;
                                  {value.start_year} - {value.end_month}
                                  &nbsp;/&nbsp;{value.end_year}
                                </span>
                              </div>
                              {/* heading */}
                              <div className="heading">{value.job_title}</div>
                              <p>experience : {value.total_experience}</p>
                              <p>{value.description}</p>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <RSDivider />

                    <h5>License Certification</h5>
                    <div className="group-infor">
                      {formData.candidate_license_certification_list.map(
                        (value, indx) => {
                          return (
                            <div className="inner" key={indx}>
                              <div className="sub-heading">
                                {/* /sub-heading */}
                                {value.issue_organization}
                              </div>
                              {/* heading */}
                              <div className="heading">
                                {value.license_name}
                              </div>
                              <p>
                                {new Date(value.issue_date).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}
                                &nbsp; &nbsp; - &nbsp; &nbsp;
                                {new Date(value.expiry_date).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}
                              </p>
                              <p>{value.description}</p>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <RSDivider />
                    {JobAppliedDetail["question_select"].length > 0 ? (
                      <QuestionAnswersTimeline
                        questions={JobAppliedDetail["question_select"]}
                        answers={formData.question_ans}
                      />
                    ) : null}
                    <RSDivider />

                    {/* <div className="group-skill group-col-2" ref={progressRef}>
                      <div className="inner cl2">
                        <h5>management skills</h5>d
                        <div className="wd-cv-skill">
                          <div className="progress-item">
                            <div className="progress-heading">
                              <div className="heading-progress">HTML & cSS</div>
                              <Progress targetHeight={targetHeight} done="60" />
                            </div>
                          </div>
                        </div>
                        <div className="wd-cv-skill">
                          <div className="progress-item">
                            <div className="progress-heading">
                              <div className="heading-progress">word</div>
                              <Progress targetHeight={targetHeight} done="90" />
                            </div>
                          </div>
                        </div>
                        <div className="wd-cv-skill">
                          <div className="progress-item">
                            <div className="progress-heading">
                              <div className="heading-progress">Excel</div>
                              <Progress targetHeight={targetHeight} done="90" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="inner cl2">
                        <h5>Design</h5>
                        <div className="wd-cv-skill">
                          <div className="progress-item">
                            <div className="progress-heading">
                              <div className="heading-progress">Figma</div>
                              <Progress targetHeight={targetHeight} done="80" />
                            </div>
                          </div>
                        </div>
                        <div className="wd-cv-skill">
                          <div className="progress-item">
                            <div className="progress-heading">
                              <div className="heading-progress">Photoshop</div>
                              <Progress targetHeight={targetHeight} done="70" />
                            </div>
                          </div>
                        </div>
                        <div className="wd-cv-skill">
                          <div className="progress-item">
                            <div className="progress-heading">
                              <div className="heading-progress">
                                Ilustration
                              </div>
                              <Progress targetHeight={targetHeight} done="90" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5>Portfolio</h5>
                    <div className="video-thumb">
                      <div className="content-tab2">
                        <div className="inner">
                          <div className="thumb">
                            <img
                              src={require("../../../../assets/images/review/thumbv3.jpg")}
                              alt="images"
                            />
                            <Video />
                          </div>
                        </div>
                      </div>
                      <Gallery />
                    </div> */}

                    <div className="form-candidate">
                      <h5>Contact Candidate</h5>
                      {/* <form> */}
                      <div className="group-input">
                        <div className="ip">
                          <label>Subject</label>
                          <input type="text" placeholder="Subject" />
                        </div>
                        <div className="ip">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Name"
                            value="Tony Nguyen |"
                          />
                        </div>
                      </div>
                      <div className="ip out">
                        <label>Email</label>
                        <input
                          type="email"
                          placeholder="Email"
                          value="jobtex@mail.com"
                        />
                      </div>
                      <div className="ip out">
                        <label>Message</label>
                        <textarea placeholder="Message..."></textarea>
                      </div>
                      <button>Send Private Message</button>
                      {/* </form> */}
                    </div>
                  </TabPanel>
                  {/* <TabPanel className="inner-content animation-tab">
                    <h5>About me</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart, world-class
                      campaigns across a variety of mediums?
                    </p>
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <h5>About Company</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart.
                    </p>
                  </TabPanel> */}
                </div>
              </Tabs>
            </div>
            <div className="col-lg-4">
              <div className="cv-form-details stc po-sticky job-sg">
                <ul className="list-infor">
                  <li>
                    {" "}
                    <div className="category">
                      <b>Job Type</b>
                    </div>
                    <div className="detail">
                      {formData.candidate_job_type.map((value, indx) => {
                        return <span key={indx}>{value.label},&nbsp;</span>;
                      })}
                    </div>
                  </li>
                  {/* <li>
                    <div className="category">Career Finding</div>
                    <div className="detail">UI UX Designer</div>
                  </li> */}
                  <li>
                    <div className="category">
                      <b>Location</b>
                    </div>
                    <div className="detail">{formData.candidate_location}</div>
                  </li>
                  <li>
                    <div className="category">
                      <b>Phone Number</b>
                    </div>
                    <div className="detail">
                      {formData.candidate_details.phone_no}
                    </div>
                  </li>
                  <li>
                    <div className="category">
                      <b>Email</b>
                    </div>
                    <div className="detail">
                      {formData.candidate_details.email}
                    </div>
                  </li>
                  <li>
                    <div className="category">
                      <b>Expected Salary</b>
                    </div>
                    <div className="detail">{formData.expected_salary}</div>
                  </li>

                  {/* <li>
                    <div className="category">Offered Salary</div>
                    <div className="detail">$2500/Month</div>
                  </li> */}
                  <li>
                    <div className="category">
                      <b>Experience </b>
                    </div>
                    <div className="detail">{formData.total_experience}</div>
                  </li>
                  <li>
                    <div className="category">
                      <b>Language</b>
                    </div>
                    <div className="detail">
                      {formData.candidate_language.map((value, indx) => {
                        return <span key={indx}>{value.name},&nbsp;</span>;
                      })}
                    </div>
                  </li>
                  {/* <li>
                    <div className="category">Age</div>
                    <div className="detail">26 Years Old</div>
                  </li> */}
                  {/* <li>
                    <div className="category">Qualification</div>
                    <div className="detail">Master Degree</div>
                  </li> */}
                </ul>
                {formData.candidate_details.social_link_fb ||
                formData.candidate_details.social_link_in ||
                formData.candidate_details.social_link_tw ||
                formData.candidate_details.social_link_insta ||
                formData.candidate_details.social_link_yt ? (
                  <>
                    <div className="category wd-social d-flex aln-center">
                      <b>Socials:</b>
                      <ul className="list-social d-flex aln-center">
                        {formData.candidate_details.social_link_fb ? (
                          <li>
                            <Link
                              to={formData.candidate_details.social_link_fb}
                              target="_blank"
                            >
                              <i className="icon-facebook"></i>
                            </Link>
                          </li>
                        ) : null}
                        {formData.candidate_details.social_link_in ? (
                          <li>
                            <Link
                              to={formData.candidate_details.social_link_in}
                              target="_blank"
                            >
                              <i className="icon-linkedin2"></i>
                            </Link>
                          </li>
                        ) : null}
                        {formData.candidate_details.social_link_tw ? (
                          <li>
                            <Link
                              to={formData.candidate_details.social_link_tw}
                              target="_blank"
                            >
                              <i className="icon-twitter"></i>
                            </Link>
                          </li>
                        ) : null}

                        {formData.candidate_details.social_link_insta ? (
                          <li>
                            <Link
                              to={formData.candidate_details.social_link_insta}
                              target="_blank"
                            >
                              <i className="icon-instagram1"></i>
                            </Link>
                          </li>
                        ) : null}
                        {formData.candidate_details.social_link_yt ? (
                          <li>
                            <Link
                              to={formData.candidate_details.social_link_yt}
                              target="_blank"
                            >
                              <i className="icon-youtube"></i>
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </>
                ) : null}
                {/* <div className="preview-cv mgt-32">
                  <div className="title">Samle_cv_jobitex</div>
                  <div className="category">PDF</div>
                  <div className="icon icon-file-pdf"></div>
                </div> */}
                <a
                  className="btn-dowload mt-2"
                  onClick={() => {
                    downloadFile(
                      `${formData.down_cover_letter}`,
                      "cover_letter"
                    );
                  }}
                >
                  <i className="icon-download"></i> Download Cover Letter
                </a>
                <a
                  className="btn-dowload mt-2"
                  onClick={() => {
                    downloadFile(`${formData.down_resume}`, "resume");
                  }}
                >
                  <i className="icon-download"></i> Download CV
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
      <Gotop />
    </>
  );
}

export default ManageCandidatePreview;
