// Importing the Apps constant if needed
import { Apps } from "../utils/constants";
import {
  FaRupeeSign,
  FaDollarSign,
  FaEuroSign,
  FaPoundSign,
  FaYenSign,
} from "react-icons/fa";

/** ^*
 * Main Url Path get.
 */
export const getAppUrl = () => {
  // if (n) {
  //   // If a value (n) is passed, return it directly
  //   return [n, window.location.hostname, window.location.pathname];
  // }

  const hostname = window.location.hostname;
  const pathname = window.location.pathname;

  // Define regular expressions for paths
  const mainPathRegex = /^\/$/; // Matches the root path "/"
  const employerPathRegex = /^\/employer(\/|$)/; // Matches "/employer" and anything under it

  // Determine which path matches
  if (mainPathRegex.test(pathname)) {
    return 1; //  return [1, hostname, pathname]; // Candidate app
  }
  if (employerPathRegex.test(pathname)) {
    return 2; //return [2, hostname, pathname]; // Employer app
  }

  // return ["unknown", hostname, pathname]; // Fallback for unmatched paths
};
/**
 * Retrieves the app name or subdomain based on the current hostname. ~*
 */
export const getApp = () => {
  const hostname = window.location.hostname;
  const subdomain = getSubdomain(hostname);
  const main = Apps.find((app) => app.main);
  if (!main) throw new Error("Must have main App");
  if (subdomain === "") return main.app;
  const app = Apps.find(
    (app) =>
      subdomain === app.subdomain && app.path === window.location.pathname
  );
  if (!app) return main.app;
  return app.app;
  console.log("Subdomain:", subdomain);
};

/**
 * Extracts the subdomain from the given hostname.
 * @param {string} hostname - The hostname from which to extract the subdomain.
 * @returns {string} - The subdomain or an empty string if none exists.
 */
const getSubdomain = (hostname) => {
  const locationParts = hostname.split(".");
  let sliceTill = -2;

  // Check if the hostname is localhost or similar (e.g., localhost:3000)
  const isLocalHost = hostname.includes("localhost");

  if (isLocalHost) {
    sliceTill = -1; // Adjust slicing for localhost (no subdomain)
  }

  // Extract the subdomain by slicing till the appropriate part
  return locationParts.slice(0, sliceTill).join("."); // Return empty string if no subdomain exists
};

export const getPriceIcons = (value) => {
  const styles = {
    display: "inline-flex", // Corrected syntax
    alignItems: "center", // Corrected syntax
    justifyContent: "center", // Corrected syntax
    backgroundColor: "#14a077", // Corrected syntax
    color: "white", // Corrected syntax
    borderRadius: "100%", // Circular shape
    width: "23px", // Adjust size as needed
    height: "23px", // Adjust size as needed
    fontSize: "15px", // Adjust icon size
  };

  const iconMap = {
    1: <FaRupeeSign />,
    2: <FaDollarSign />,
    3: <FaEuroSign />,
    4: <FaPoundSign />,
    5: <FaYenSign />,
  };

  const icon = iconMap[value] || <FaRupeeSign />; // Default to Rupee Sign

  return <div style={styles}>{icon}</div>;
};
