import { useCallback } from "react";

export const useDebounce = (callback, delay = 300) => {
  let timeoutId = null;

  return useCallback(
    (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        callback(...args);
        timeoutId = null;
      }, delay);
    },
    [callback, delay]
  );
};
