import { React, lazy } from "react";
import CandidateDashboard from "../../pages/Home/HomePage";
import CandidateProfile from "../../pages/Profile/ProfilePage";
import CandidateCardPage from "../../components/card-style/cardComp";
import Login from "../../pages/Auth/Login";
import CreateAccount from "../../pages/Auth/CreateAccount";
import Page404 from "../../pages/Page404";
import ManageCandidatePreview from "../../Employer/components/ManageJobPost/PreviewCandidate.jsx/ManageCandidatePreview";

const routesConfigCandidate = [
  { path: "/", element: <CandidateDashboard />, isProtected: false },
  { path: "/login", element: <Login />, isProtected: false },
  { path: "/create-account", element: <CreateAccount />, isProtected: false },
  { path: "/profile", element: <CandidateProfile />, isProtected: true },
  { path: "/card", element: <CandidateCardPage />, isProtected: true },
  { path: "/card", element: <ManageCandidatePreview />, isProtected: true },

  { path: "*", element: <Page404 /> }, //<Navigate to="/" replace /> },
];

export default routesConfigCandidate;
