import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useLocationList = () => {
  const [jobLocationList, setJobLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      apiService.GetJobLocationList();
      // setWherePlaceSearchingList([...fetchWherePlaceSearching, ...fetchWherePlaceSearching(fetchWherePlaceSearching.length, 30)]);
      setIsLoading(false);
    }, 1000);
  };

  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= jobLocationList.length - 1) {
      loadMore();
    }
  };

  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {/* {loading && <FixedLoader />} */}
      </>
    );
  };
  useEffect(() => {
    const fetchLocationList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetJobLocationList();
        setJobLocations(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch employment types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocationList();
  }, []);

  return {
    jobLocationList,
    onItemsRendered,
    renderMenu,
    loadMore,
    isLoading,
    error,
  };
};
