import React from "react";
// import "../style.scss";

function Preloader() {
  return (
    <div className="preload preload-container">
      <div className="preload-logo">
        <div className="spinner" />
      </div>
    </div>
  );
}
function CuLoader() {
  return (
    <div className="preload-logo2">
      <div className="spinner" />
    </div>

  );
};
const LoaderMount = {
  Preloader,
  CuLoader
}
export default LoaderMount;

