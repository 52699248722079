import React from "react";
import { Form, RadioGroup, Radio, FormControlLabel } from "rsuite";

const RadioButton = React.forwardRef((props, ref) => {
  const { name, label, list, ...rest } = props;
  return (
    <Form.Group ref={ref}>
      <h4>
        <Form.ControlLabel>{label}</Form.ControlLabel>
      </h4>
      <RadioGroup name={name} {...rest}>
        {list.map((mount) => (
          <Radio key={mount.value} value={mount.value}>
            <b>
              <h6>{mount.label}</h6>
            </b>
          </Radio>
        ))}
      </RadioGroup>
    </Form.Group>
  );
});

export default RadioButton;
