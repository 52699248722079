import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

import EditCreateModal from "./DemoTesting";

import Session from "../../api/session";
import axios from "../../api/axios";
import domain from "../../api/constent";
import apiService from "../../api/apiService";

import { sweetAlert } from "../../utils/sweetAlert";

export default function RoleComp() {
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isStillInRole, setIsStillInRole] = useState(0);

  const [formData, setFormData] = useState({
    jobTitle: "",
    companyName: "",
    startMonth: 0,
    startYear: 0,
    endMonth: 0,
    endYear: 0,
    isStillInRole: 0,
    description: "",
    totalExperience: 0,
  });

  const [editDetails, setEditDetails] = useState(null);
  const [errors, setErrors] = useState({
    jobTitle: false,
    companyName: false,
    startMonth: false,
    startYear: false,
    endMonth: false,
    endYear: false,
    isStillInRole: false,
    description: false,
    totalExperience: false,
  });

  const handleCreateOpen = (item = null) => {
    setOpenCreate(true);
    setEditDetails(!!item);
    if (item) {
      setFormData({
        pid: item.id,
        jobTitle: item.job_title,
        companyName: item.company_name,
        startMonth: item.start_month,
        startYear: item.start_year,
        endMonth: item.end_month,
        endYear: item.end_year,
        isStillInRole: item.is_current,
        description: item.description,
        totalExperience: item.total_exp,
      });
    } else {
      // Reset form data for creating a new role
      setFormData({
        jobTitle: "",
        companyName: "",
        startMonth: 0,
        startYear: 0,
        endMonth: 0,
        endYear: 0,
        isStillInRole: 0,
        description: "",
        totalExperience: 0,
      });
    }
  };

  function handleCreateClose() {
    setOpenCreate(false);
    console.log(openCreate);

    // setOpenCreate(false);
    setFormData({
      jobTitle: "",
      companyName: "",
      startMonth: 0,
      startYear: 0,
      endMonth: 0,
      endYear: 0,
      isStillInRole: 0,
      description: "",
      totalExperience: 0,
    });
    setErrors({
      jobTitle: false,
      companyName: false,
      startMonth: false,
      startYear: false,
      // endMonth: false,
      // endYear: false,
      // isStillInRole: 0,
    });
  }
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);

  const handleChange = (field, value) => {
    // setFormData((prevData) => ({
    //     ...prevData,
    //     [field]: value,
    // }));
    setFormData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({
      ...prev,
      [field]: !value ? "This field is required" : null,
    }));
  };

  const handleSave = async () => {
    const newErrors = {
      jobTitle: !formData.jobTitle,
      companyName: !formData.companyName,
      startMonth: !formData.startMonth,
      startYear: !formData.startYear,
      endMonth: !formData.endMonth,
      endYear: !formData.endYear && !formData.isStillInRole,
      description: !formData.description,
      totalExperience: !formData.totalExperience,
    };

    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return; // Don't submit if any field has an error
    }

    try {
      let mountData = {
        registration_id: Session.getDataSessionStore().id,
        job_title: formData.jobTitle,
        company_name: formData.companyName,
        start_month: formData.startMonth,
        start_year: parseInt(formData.startYear, 10),
        end_month:
          formData.isStillInRole != 1 ? parseInt(formData.endMonth, 10) : 0,
        end_year:
          formData.isStillInRole != 1 ? parseInt(formData.endYear, 10) : 0,
        is_current: formData.isStillInRole ?? 0,
        description: formData.description,
        total_experience: formData.totalExperience,
      };
      let mountData2 = {
        p_id: formData.pid,
        job_title: formData.jobTitle,
        company_name: formData.companyName,
        start_month: formData.startMonth,
        start_year: parseInt(formData.startYear, 10),
        end_month:
          formData.isStillInRole != 1 ? parseInt(formData.endMonth, 10) : 0,
        end_year:
          formData.isStillInRole != 1 ? parseInt(formData.endYear, 10) : 0,
        is_current: formData.isStillInRole ?? 0,
        description: formData.description,
        total_experience: formData.totalExperience,
      };

      console.log(mountData2);
      if (editDetails) {
        const response = await apiService.getDetailsRoleUpdate(
          mountData2.p_id,
          mountData2
        );
        console.log("Data updated:", response.data);
        sweetAlert("Success!", "Your Career History has been updated.", 1);
      }
      if (!editDetails) {
        const response = await apiService.getDetailsRoleCreate(mountData);
        console.log("Data saved:", response.data);
        sweetAlert("Success!", "Your Career History have been Inserted.", 1);
      }
      handleCreateClose();
      fetchRoleList();
    } catch (error) {
      console.error("Error saving data:", error);
      sweetAlert("Error!", "Failed to update personal details. Try again.", 2);
    }
  };

  const fetchRoleList = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await apiService.getDetailsRoleList();
      setLists(data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoleList();
  }, []);

  function reChange(event) {
    if (event != false) {
      formData.isStillInRole = 1;
    } else {
      formData.isStillInRole = 0;
    }
  }

  function handleClose() {
    setFormData({
      jobTitle: "",
      companyName: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      isStillInRole: 0,
      description: "",
      totalExperience: 0,
    });
  }
  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  return (
    <div>
      <List>
        {loading ? (
          <ShimmerCategoryItems mode="light" />
        ) : (
          lists.map((item) => (
            <ListItem
              key={item.id}
              sx={{
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                mb: 2,
                p: 2,
                position: "relative",
              }}
            >
              <IconButton
                onClick={() => handleCreateOpen(item)}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  borderRadius: "50%",
                  padding: "8px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <EditIcon />
              </IconButton>

              <ListItemText
                primary={<Typography variant="h6">{item.job_title}</Typography>}
                secondary={
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {item.company_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {`${item.start_month} / ${item.start_year} - ${
                          item.end_month
                            ? item.end_month + "/" + item.end_year
                            : "Present"
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Link href="#" underline="hover" color="primary">
                        Review this company
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <b>More</b>
                        {/* <IconButton onClick={handleToggle} size="small">
                                                    {openRole ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <Collapse in={openRole} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <ListItem>
                                                        <ListItemText primary="• Key Responsibilities" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="• Managed project timelines and deliverables." />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="• Coordinated with cross-functional teams." />
                                                    </ListItem>
                                                </List>
                                            </Collapse> */}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          ))
        )}
      </List>
      <div className="pt-2">
        <Button size="small" variant="contained" onClick={handleCreateOpen}>
          Add Role
        </Button>
      </div>

      {/* Modal Dialog */}
      <Dialog
        open={openCreate}
        onClose={handleCreateClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {editDetails ? "Edit Role" : "Add Role"}
          <IconButton
            edge="end"
            color="primary"
            onClick={handleCreateClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>{" "}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingBottom: 3,
          }}
        >
          {/* Job Title */}
          <TextField
            label="Job Title"
            variant="outlined"
            fullWidth
            value={formData.jobTitle}
            onChange={(e) => handleChange("jobTitle", e.target.value)}
            error={errors.jobTitle}
            helperText={errors.jobTitle ? "Job Title is required" : ""}
            sx={{ marginBottom: 2, marginTop: 3 }}
          />

          {/* Company Name */}
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            value={formData.companyName}
            onChange={(e) => handleChange("companyName", e.target.value)}
            error={errors.companyName}
            helperText={errors.companyName ? "Company Name is required" : ""}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Total Experience"
            variant="outlined"
            fullWidth
            type="number"
            value={formData.totalExperience || ""}
            onChange={(e) =>
              handleChange("totalExperience", Number(e.target.value))
            }
            error={errors.totalExperience}
            helperText={
              errors.totalExperience ? "Total Experience is required" : ""
            }
            placeholder="Enter years of experience"
            sx={{ marginBottom: 2 }}
            inputProps={{ min: 0 }} // Prevents negative numbers
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <FormControl
              fullWidth
              sx={{ marginBottom: 2 }}
              error={errors.startMonth}
            >
              <InputLabel>Start Month</InputLabel>
              <Select
                value={formData.startMonth || ""}
                onChange={(e) => handleChange("startMonth", e.target.value)}
                displayEmpty
                label="Start Month"
              >
                <MenuItem value="" disabled>
                  {" "}
                  Start Month
                </MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.startMonth && (
                <div style={{ color: "red" }}>Start Month is required</div>
              )}
            </FormControl>

            <FormControl
              fullWidth
              sx={{ marginBottom: 2 }}
              error={errors.startYear}
            >
              <InputLabel>Start Year</InputLabel>
              <Select
                value={formData.startYear}
                onChange={(e) => handleChange("startYear", e.target.value)}
                label="Start Year"
              >
                {["2020", "2021", "2022", "2023", "2024"].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.startYear && (
                <div style={{ color: "red" }}>Start Year is required</div>
              )}
            </FormControl>
          </div>

          {/* End Date or Still in Role */}
          <div style={{ display: "flex", gap: "10px" }}>
            <FormControl
              fullWidth
              sx={{ marginBottom: 2 }}
              error={errors.endMonth}
            >
              <InputLabel>End Month</InputLabel>
              <Select
                value={formData.endMonth}
                onChange={(e) => handleChange("endMonth", e.target.value)}
                label="End Month"
                disabled={isStillInRole}
              >
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.endMonth && (
                <div style={{ color: "red" }}>End Month is required</div>
              )}
            </FormControl>

            <FormControl
              fullWidth
              sx={{ marginBottom: 2 }}
              error={errors.endYear}
            >
              <InputLabel>End Year</InputLabel>
              <Select
                value={formData.endYear}
                onChange={(e) => handleChange("endYear", e.target.value)}
                label="End Year"
                disabled={isStillInRole}
              >
                {["2020", "2021", "2022", "2023", "2024"].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.endYear && (
                <div style={{ color: "red" }}>End Year is required</div>
              )}
            </FormControl>

            {/* Checkbox for "Still in Role"  error={errors.endYear} */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formData.isStillInRole}
                    onChange={(e) => {
                      setIsStillInRole(e.target.checked);
                      reChange(e.target.checked);
                    }}
                  />
                }
                label="Still in Role"
                sx={{ marginBottom: 2 }}
              />{" "}
            </FormControl>
          </div>

          {/* Description */}
          <TextField
            label="Description"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            // error={errors.description}
            // helperText={errors.description ? 'Description is required' : ''}
            sx={{
              marginBottom: 2,
              padding: "10px", // Adds some padding to make it feel less cramped
              minHeight: "120px", // Ensures the field has a minimum height for better visibility
              backgroundColor: "#fafafa",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            sx={{
              backgroundColor: "#f44336",
              color: "#fff",
              "&:hover": { backgroundColor: "#e53935" },
            }}
            onClick={handleCreateClose} //handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            sx={{
              backgroundColor: "#4caf50",
              color: "#fff",
              "&:hover": { backgroundColor: "#45a049" },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
