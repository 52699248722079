//main domain
const Root_URL = process.env.REACT_APP_ROOT_URL;
const Main_URL = process.env.REACT_APP_DB_URL;
const JOB_FEATURED_URL = `${Main_URL}/job_featured`;
const Job_Category_URL = `${Main_URL}/job_category_list`;
const Job_Posting_List_URL = `${Main_URL}/job_posting_list`;
const Job_Posting_List_Searching_URL = `${Main_URL}/job_posting_list_Searching`;
const Job_Keyword_Find_URL = `${Main_URL}/job-keyword`;
const Job_Keyword_Search_List = `${Main_URL}/job-keyword-search`;
const Job_Select_Search_List = `${Main_URL}/job-select-search`;
const Job_Select_Where_Place_List = `${Main_URL}/job-where-place-select-search`;

const Profile_Details = `${Main_URL}/profile_details`;

const Sanctum_CSRF = `${Root_URL}/sanctum/csrf-cookie`;
const LoggedIn = `${Main_URL}/profile_details`;
const CandidateRegister_URL = `${Main_URL}/candidate/register`;
const Login_URL = `${Main_URL}/candidate/login`;
const Logout_URL = `${Main_URL}/logout`;
const Registration_URL = `${Main_URL}/candidate/register`;
const EmployerRegister_URL = `${Main_URL}/employer/register`;
const EmployerLogin_URL = `${Main_URL}/employer/login`;

// Profile Section
const Personal_Details = `${Main_URL}/profile_details`;
const Update_Personal_Details = `${Main_URL}/update_personal_details`;
const Candidate_Additional_Details = `${Main_URL}/candidate_addition_details`;
// Resume
const Candidate_Resume_Upload = `${Main_URL}/candidate/candidate_resume_store`;
const Candidate_Resume_List = `${Main_URL}/candidate/resume_list_show/`;
const Candidate_Resume_Update = `${Main_URL}/candidate/resumes_list_edit/`;
const Candidate_Resume_Delete = `${Main_URL}/candidate/resumes_list_delete`;
// Coverletter
const Candidate_CoverL_Upload = `${Main_URL}/candidate/candidate_cover_letter_store`;
const Candidate_CoverL_List = `${Main_URL}/candidate/candidate_cover_letter_list_show/`;
const Candidate_CoverL_Update = `${Main_URL}/candidate/candidate_cover_letter_edit/`;
const Candidate_CoverL_Delete = `${Main_URL}/candidate/candidate_cover_letter_delete`;

// Candidate job location
const Cadidate_job_location_List = `${Main_URL}/candidate_job_location_list`;

// Career History
const Career_History_List = `${Main_URL}/career-history-list/`;
const Career_History_Create = `${Main_URL}/career-history-create`;
const Career_History_Edit = `${Main_URL}/career-history-update/`;
const Career_History_delete = `${Main_URL}/career-history-delete/`;

// Education
const Education_List = `${Main_URL}/candidate-education-list/`;
const Candidate_Education_Create = `${Main_URL}/candidate-education-create`;
const Candidate_Education_Edit = `${Main_URL}/candidate-education-update/`;
const Candidate_Education_delete = `${Main_URL}/candidate-education-delete/`;

// Licenses
const Licenses_List = `${Main_URL}/candidate-license-list/`;
const Candidate_Licenses_Create = `${Main_URL}/candidate-license-create`;
const Candidate_Licenses_Edit = `${Main_URL}/candidate-license-update/`;
const Candidate_Licenses_delete = `${Main_URL}/candidate-license-delete/`;

// Skills
const Candidate_Skills_List = `${Main_URL}/candidate-skill-list/`;
const Candidate_Skill_Create = `${Main_URL}/candidate-skill-create`;
const Candidate_Skill_Edit = `${Main_URL}/candidate-skill-update/`;
const Candidate_Skill_delete = `${Main_URL}/candidate-skill-delete/`;

// Languages
const Candidate_Languages_List = `${Main_URL}/candidate-language-list/`;
const Candidate_Languages_Create = `${Main_URL}/candidate-language-create`;
const Candidate_Languages_Edit = `${Main_URL}/candidate-language-update/`;
const Candidate_Languages_delete = `${Main_URL}/candidate-language-delete/`;

// All Languages & skill's
const all_Languages_List = `${Main_URL}/all-language-list/`;
const all_Skill_list = `${Main_URL}/all-skill-list`;

// Get All List
const Get_Employment_Type_List = `${Main_URL}/employer/get-employment-type`;
const Get_Skills_List = `${Main_URL}/employer/all-skills`;
const Get_Department_List = `${Main_URL}/employer/get-department-type`;
const Get_Role_List = `${Main_URL}/employer/get-role-type/`;
const Get_Working_List = `${Main_URL}/employer/get-working-type`;
const Get_Job_Desc_List = `${Main_URL}/employer/get-job-location`;
const Get_Work_Exp_List = `${Main_URL}/employer/get-work-experience`;
const Get_Salary_Type_List = `${Main_URL}/employer/get-salary-type`;
const Get_Salary_Process_Type_List = `${Main_URL}/employer/get-salary-process-type`;
const Get_Salary_Range_List = `${Main_URL}/employer/get-salary-range`;
const Get_Company_Industry_List = `${Main_URL}/employer/get-company-industry`;
const Get_Education_Qualification_List = `${Main_URL}/employer/get-education-qualification-list`;
const Get_Candidate_Industry_List = `${Main_URL}/employer/get-candidate-industry`;
const Get_Notification_Type_List = `${Main_URL}/employer/get-notification-list`;
const Get_Company_Size_List = `${Main_URL}/employer/get-company-size-list`;
const Get_Company_Sub_User_List = `${Main_URL}/employer/get-email-list`;
// open filters
const Open_Employment_Type_List = `${Main_URL}/employer/open-employment-type`;
const Open_Salary_Type_List = `${Main_URL}/employer/open-salary-type`;
const Open_Salary_Process_Type_List = `${Main_URL}/employer/open-salary-process-type`;
const Open_Salary_Range_List = `${Main_URL}/employer/open-salary-range`;
const Open_Listing_MyTime_List = `${Main_URL}/employer/open-listing-any-time-type`;

// Apply Job
const Apply_Job = `${Main_URL}/applied_job_candidate`;

// Suggestion List
const Employer_Suggestion_QuestionsList = `${Main_URL}/employer/qusetions_suggestion_list`;
// Employer
const Employer_QuestionsList = `${Main_URL}/employer/questions_get`;
const Employer_QuestionsStore = `${Main_URL}/employer/questions_store`;
const Employer_QuestionsUpdate = `${Main_URL}/employer/questions_put/`;
const Employer_QuestionsDelete = `${Main_URL}/employer/questions_del/`;
const Employer_Job_Posting = `${Main_URL}/employer/job_vacancy_posting`;

// Employer manageJobs
const Employer_ManageJobAndResponse = `${Main_URL}/employer/manage-job-posting-list`;
const Employer_ManageJobCandidateList = `${Main_URL}/employer/applied-job-candidate-list`;
const Employer_JobCandateStatusUpdateList = `${Main_URL}/employer/applied-job-candi-status-manage`;
const Employer_ManageJobDeleteList = `${Main_URL}/employer/manage-job-posting-list-delete`;

// PDF
const Employer_ManageJobCandidateFileDownload = `${Main_URL}/click_download`;

const domain = {
  JOB_FEATURED_URL,
  Profile_Details,
  Job_Category_URL,
  Root_URL,
  Main_URL,
  LoggedIn,
  Logout_URL,
  Login_URL,
  Sanctum_CSRF,
  CandidateRegister_URL,
  EmployerRegister_URL,
  EmployerLogin_URL,
  Registration_URL,
  Job_Posting_List_URL,
  Job_Posting_List_Searching_URL,
  Job_Keyword_Find_URL,
  Job_Keyword_Search_List,
  Job_Select_Search_List,
  Job_Select_Where_Place_List,
  Cadidate_job_location_List,
  Candidate_Resume_Upload,
  Candidate_Resume_List,
  Candidate_Resume_Update,
  Candidate_Resume_Delete,
  Candidate_CoverL_Upload,
  Candidate_CoverL_List,
  Candidate_CoverL_Update,
  Candidate_CoverL_Delete,
  Personal_Details,
  Update_Personal_Details,
  Candidate_Additional_Details,
  Career_History_List,
  Career_History_Create,
  Career_History_Edit,
  Career_History_delete,
  Education_List,
  Candidate_Education_Create,
  Candidate_Education_Edit,
  Candidate_Education_delete,
  Licenses_List,
  Candidate_Licenses_Create,
  Candidate_Licenses_Edit,
  Candidate_Licenses_delete,
  Candidate_Skills_List,
  Candidate_Skill_Create,
  Candidate_Skill_Edit,
  Candidate_Skill_delete,
  Candidate_Languages_List,
  Candidate_Languages_Create,
  Candidate_Languages_Edit,
  Candidate_Languages_delete,
  Apply_Job,
  all_Skill_list,
  all_Languages_List,
  Employer_QuestionsList,
  Employer_Suggestion_QuestionsList,
  Employer_QuestionsStore,
  Employer_QuestionsUpdate,
  Employer_QuestionsDelete,
  Employer_Job_Posting,
  Employer_ManageJobAndResponse,
  Employer_ManageJobDeleteList,
  Employer_ManageJobCandidateList,
  Employer_JobCandateStatusUpdateList,
  Employer_ManageJobCandidateFileDownload,
  Get_Employment_Type_List,
  Get_Skills_List,
  Get_Department_List,
  Get_Role_List,
  Get_Working_List,
  Get_Job_Desc_List,
  Get_Work_Exp_List,
  Get_Salary_Type_List,
  Get_Salary_Process_Type_List,
  Get_Salary_Range_List,
  Get_Company_Industry_List,
  Get_Education_Qualification_List,
  Get_Candidate_Industry_List,
  Get_Notification_Type_List,
  Get_Company_Size_List,
  Get_Company_Sub_User_List,
  Open_Employment_Type_List,
  Open_Salary_Type_List,
  Open_Salary_Process_Type_List,
  Open_Salary_Range_List,
  Open_Listing_MyTime_List,
};
export default domain;
