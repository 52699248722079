import AXIOS from "./axios";
import Static from "./constent";
function getDataSessionStore() {
  const storedData = sessionStorage.getItem("user");
  return storedData ? JSON.parse(storedData) : null;
}

function getDataEmployerSessionStore() {
  const storeEmployer = sessionStorage.getItem("employer");
  return storeEmployer ? JSON.parse(storeEmployer) : null;
}
function getDataSessionClear(value) {
  return sessionStorage.removeItem(value);
}
function getChecking() {
  const getDetails = getDataSessionStore();
  // getDetails.type == 1 &&
  if (getDetails && getDetails.token != "" &&  getDetails.email != "") {
    return true;
  }
  return false;
}
function getEmployerChecking() {

  const employerDetails = getDataEmployerSessionStore();

  if (
    employerDetails &&
    employerDetails.encryptId !== "" &&
    employerDetails.token !== "" &&
    employerDetails.type === 2
  ) {
    console.log(employerDetails);
    // Employer is valid
    return true;
  } else {
    return false;
  }
}
function getData() {
  return sessionStorage.getItem("user");
}
async function getCSRF() {
  return await AXIOS.get(Static.Sanctum_CSRF);
}

const rootSession = {
  getDataSessionStore,
  getData,
  getCSRF,
  getDataSessionClear,
  getChecking,
  getDataEmployerSessionStore,
  getEmployerChecking,
};
export default rootSession;
