import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useCandidateIndustriesList = () => {
  const [candidateIndustriesList, setCandidateIndustriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCandidateIndustriesList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetCandidateIndustryList();
        setCandidateIndustriesList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch CompanyIndustriesList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCandidateIndustriesList();
  }, []);

  return { candidateIndustriesList, isLoading, error };
};
