import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useSalaryProcessTypeList = () => {
  const [salaryProcessTypeList, setSalaryProcessTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalaryProcessTypeList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetSalaryProcessTypeList();
        setSalaryProcessTypeList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch salaryProcessTypeList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSalaryProcessTypeList();
  }, []);

  return { salaryProcessTypeList, isLoading, error };
};
