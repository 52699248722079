import React, { useCallback } from "react";
import { AutoComplete as RSAutoComplete } from "rsuite";
import { useDebounce } from "../../hooks/useDebounce";
import { useResizeObserver } from "../../hooks/useResizeObserver";

const SearchAutoComplete = ({ data, onSearch,onClickHandler }) => {
  useResizeObserver();

  const debouncedSearch = useDebounce((value) => {
    if (typeof onSearch === "function") {
      onSearch(value);
    }
  });

  const handleChange = useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <RSAutoComplete
      data={data}
      onChange={handleChange}
      onClick={onClickHandler}
      style={{ width: 224 }}
      placeholder="Enter Keywords"
    />
  );
};

export default SearchAutoComplete;
