import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useEmploymentTypes = () => {
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmploymentTypes = async () => {
      try {
        // setIsLoading(true);
        const data = await apiService.GetEmploymentType();
        // Update employment types state
        setEmploymentTypes(data);

        // Check if data.data is not empty
        if (data?.data?.length != 0) {
          setIsLoading(true); // Data is present, stop loading
        } else {
          console.warn("No data found");
          setIsLoading(false); // No data, stop loading
        }
        setError(null);
      } catch (err) {
        setIsLoading(false);
        setError("Failed to fetch employment types");
      }
      //  finally {
      //   setIsLoading(false);
      // }
    };

    fetchEmploymentTypes();
  }, []);

  return { employmentTypes, isLoading, error };
};
