import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SelectLocation from "../dropdown";

const select1 = [
  { value: "s1", label: "Design & Creative" },
  { value: "s2", label: "Design" },
  { value: "s3", label: "Ux/Ui" },
];

Sidebar.propTypes = {
  onUpdateValue: PropTypes.func.isRequired,
  onUpdateJobStateValue: PropTypes.string,
  onUpdateJobCategoryValue: PropTypes.string,

  // initialValues: PropTypes.shape({
  //   searchValue: PropTypes.string,
  //   selectedJobStatus: PropTypes.string,
  //   selectedCategory: PropTypes.string,
  // }).isRequired,
};

function Sidebar(props) {
  const { onUpdateValue, onUpdateJobStateValue, onUpdateJobCategoryValue } =
    props;

  const [search, setSearch] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    onUpdateValue(search); // Notify parent component when answers change
    onUpdateJobStateValue(jobStatus);
    onUpdateJobCategoryValue(category);
  }, [search, jobStatus, category]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    // onUpdateValue(e.target.value);
  };

  const handleJobStatusChange = (value) => {
    setJobStatus(value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  return (
    <div className="content-left style2 po-sticky">
      <div className="inner st-filter">
        <div className="widget-filter no-scroll mb1">
          <div className="group-form">
            <div className="group-input search-ip">
              <button>
                <i className="icon-search"></i>
              </button>
              <input
                type="text"
                placeholder="Search by Title/Ref Code/Job ID"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="group-form">
            <label className="title">Job Status</label>
            <div className="group-input has-icon">
              <SelectLocation
                prop={[
                  { value: "1", label: "All Jobs" },
                  { value: "2", label: "Active Jobs" },
                  { value: "3", label: "Close Jobs" },
                  { value: "4", label: "Expired Jobs" },
                ]}
                value={jobStatus}
                onChange={handleJobStatusChange}
              />
            </div>
          </div>
          <div className="group-form">
            <label className="title">Category</label>
            <div className="group-input has-icon">
              <SelectLocation
                prop={[
                  { value: "1", label: "All Category" },
                  { value: "2", label: "Hot Vancancy" },
                  { value: "3", label: "Classified" },
                ]}
                value={category}
                onChange={handleCategoryChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
