import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {
  Text as RSText,
  FlexboxGrid as RSFlexboxGrid,
  TagPicker as RSTagPicker,
  Card as RSCard,
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  Placeholder as RSPlaceholder,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder,
  Form as RSForm,
} from "rsuite";
import PhoneInput from "react-phone-input-2";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import axios from "../../api/axios";
import { sweetAlert } from "../../utils/sweetAlert";

import apiService from "../../api/apiService";
import domain from "../../api/constent";
import Session from "../../api/session";
import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";
import { Api } from "@mui/icons-material";
import AuthUser from "../../middleware/AuthMiddleware";
import { debounce } from "lodash";
export default function CardComp(props) {
  const { checkUpdateShow = false } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [getJson, setTempJson] = useState({
    candidate_id: Session.getDataSessionStore().id,
    candidate_email: Session.getDataSessionStore().email,
    candidate_profile_img: "",
    first_name: "",
    last_name: "",
    email: "",
    location_ids: 0,
    location_name: "",
    pref_location_ids: 0,
    pref_location_name: "",
    phone_no: "",
    country_code: "",
    salary_types: 0,
    job_type: [],
    skill_type: [],
    exp_salary_type: 0,
  });
  const [wherePlaceSearchingList, setWherePlaceSearchingList] = useState([]);
  const [getKeySkillsList, setKeySkillsList] = useState([]);
  const [employeeTypes, setEmploymentList] = useState([]);
  const [salaryTypeList, setSalaryTypeList] = useState([]);
  const [salaryRangeList, setSalaryRangeList] = useState([]);

  // Fetch personal details on component load
  useEffect(() => {
    PersonalDetails();
    fetchFilteringData();
    // setTempJson((prev) => ({
    //   ...prev,
    //   location_id: getJson.location_id,
    // }));
    fetchWherePlaceSearchingM();
    fetchkeySkillsList();
  }, []);
  const fetchkeySkillsList = async () => {
    // setIsLoading(true);
    try {
      const data = await apiService.GetKeySkillsList();
      setKeySkillsList(data);
      // setError(null);
    } catch (err) {
      console.log(err);

      // setError("Failed to fetch employment types");
    } finally {
      // setIsLoading(false);
    }
  };

  const fetchWherePlaceSearchingM = async () => {
    try {
      setLoading(true);

      const response = await axios.get(domain.Cadidate_job_location_List);
      //   console.log(response);
      setLoading(false);
      setWherePlaceSearchingList(response.data.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };
  // Do thing process
  const loadMore = () => {
    setLoading(true);
    setTimeout(() => {
      fetchWherePlaceSearchingM();
      setLoading(false);
    }, 1000);
  };

  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= wherePlaceSearchingList.length - 1) {
      loadMore();
    }
  };

  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {/* {loading && <FixedLoader />} */}
      </>
    );
  };

  const fetchFilteringData = async () => {
    // setLoading(true);
    try {
      // Make all API calls concurrently
      const [
        employmentData,
        salaryTypeData,
        salaryRangeData,
        // listingAnyTimeList,
      ] = await Promise.all([
        apiService.OpenEmploymentType(),
        apiService.OpenSalaryTypeList(),
        apiService.OpenSalaryRangeList(),
        apiService.OpenListingAnyTimeList(),
      ]);
      // console.log(`Log ${employmentData}`);

      // Update state with fetched data
      setEmploymentList(employmentData);
      setSalaryTypeList(salaryTypeData);
      setSalaryRangeList(salaryRangeData);
      //   setListingAnyTimeList(listingAnyTimeList);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      // setLoading(false);
    }
  };
  const PersonalDetails = async () => {
    try {
      setLoading(true);
      const resp = await apiService.fetchPersonalDetails();
      console.log("employee ,PSD ", resp);

      if (resp) {
        setTempJson({
          candidate_profile_img: resp.profile_img || "",
          first_name: resp.first_name || "John",
          last_name: resp.last_name || "Doe",
          email: resp.email || "john.doe@example.com",
          phone_no: resp.phone_no || "123-456-7890",
          country_code: resp.country_code || "us",
          location_ids: resp.location_id || 0,
          location_name: resp.location_name || "No location",
          pref_location_ids: resp.pref_locate_id || 0,
          pref_location_name: resp.pref_locate_name || "No Pref Location",
          job_type: resp.job_type || [],
          salary_types: resp.salary_type || 0,
          exp_salary_type: resp.exp_salary_type || 0,
          skill_type: resp.skill_type || [],
        });
      }
    } catch (error) {
      console.error("Error fetching personal details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempJson((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, country) => {
    setTempJson((prevState) => ({
      ...prevState,
      phone_no: value,
      country_code: country?.dialCode || "1",
    }));
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const resp = await apiService.updatePersonalDetails(getJson);
      if (resp) {
        handleClose();
        PersonalDetails();
        sweetAlert("Success!", "Your personal details have been updated.", 1);
      }
    } catch (error) {
      sweetAlert("Error!", "Failed to update personal details. Try again.", 2);
    } finally {
      setLoading(false);
    }
  };

  const valueJson = useMemo(
    () => ({
      name: `${getJson.first_name} ${getJson.last_name}`,
      gmail: getJson.email,
      phone: `+${getJson.country_code} ${getJson.phone_no}`,
      locationId: getJson.location_ids,
      locationName: getJson.location_name,
      prefLocationId: getJson.pref_location_ids,
      prefLocationName: getJson.pref_location_name,
      jobType: getJson.job_type,
      skillType: getJson.skill_type,
      salaryType: getJson.salary_types,
      expSalaryType: getJson.exp_salary_type,
    }),
    [getJson]
  );

  const commonStyles = {
    display: "flex",
    position: "relative",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
    color: "white",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    background:
      "radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%)",
    maxWidth: "1300px",
    height: "100%", // Ensure consistent height
  };
  return (
    <div>
      {loading ? (
        <ShimmerCategoryItems
          mode="light"
          items={1}
          gap={10}
          sx={commonStyles}
          style={{
            ...commonStyles,
            height: "100%", // Ensure the height matches
          }}
        />
      ) : (
        <>
          <Box sx={commonStyles}>
            {/* <IconButton
                        onClick={handleOpen}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            borderRadius: '50%',
                            padding: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        }}
                    >
                        <EditIcon />
                    </IconButton> */}

            <Typography variant="h5" component="div" gutterBottom>
              <b>{valueJson.name || "Name not available"}</b>
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <LocationOnIcon sx={{ marginRight: 1 }} />
              <Typography variant="body2">
                {valueJson.locationName || "Location not available"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <CallIcon sx={{ marginRight: 1 }} />
              <Typography variant="body2">
                {valueJson.phone || "Phone not available"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <MailIcon sx={{ marginRight: 1 }} />
              <Typography variant="body2">
                {valueJson.gmail || "Email not available"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <Button
                onClick={handleOpen}
                size="small"
                variant="contained"
                color="primary"
              >
                Edit
              </Button>
            </Box>
          </Box>

          {checkUpdateShow != false ? (
            <Box
              sx={{
                display: "flex",
                position: "relative",
                margin: "0 auto",
                flexDirection: "column",
                borderRadius: "20px",
                maxWidth: "1340px",
                height: "100%",
                padding: "20px", // Added padding for better spacing
              }}
            >
              <RSCard className="m-3">
                <RSCard.Body>
                  <h5>Add Additional Details</h5>

                  <RSFlexboxGrid
                    className="p-2"
                    justify="space-between"
                    spacing={16}
                  >
                    {/* Job Type */}
                    <RSFlexboxGrid.Item
                      colspan={12}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={5}
                      className="mb-3"
                    >
                      <label>Job Type:</label>
                      <RSTagPicker
                        value={valueJson.jobType}
                        placeholder="Working Types"
                        data={employeeTypes}
                        onChange={(e) =>
                          setTempJson((ps) => ({ ...ps, job_type: e }))
                        }
                        style={{ width: "100%" }}
                        tagProps={{
                          style: {
                            fontWeight: "bold",
                            backgroundColor: "rgb(52 152 254)",
                            color: "#fff",
                            padding: "3px 24px 3px 7px",
                            borderRadius: "5px",
                          },
                        }}
                      />
                    </RSFlexboxGrid.Item>

                    {/* Salary Type */}
                    <RSFlexboxGrid.Item
                      colspan={12}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      className="mb-3"
                    >
                      <label>Salary Type:</label>
                      <RSSelectPicker
                        value={valueJson.salaryType}
                        virtualized
                        placeholder="Salary Type"
                        data={salaryTypeList}
                        onChange={(e) =>
                          setTempJson((ps) => ({ ...ps, salary_types: e }))
                        }
                        style={{ width: "100%" }}
                      />
                    </RSFlexboxGrid.Item>

                    {/* Current Location */}
                    <RSFlexboxGrid.Item
                      colspan={12}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      className="mb-3"
                    >
                      <label>Current Location:</label>
                      <RSSelectPicker
                        value={valueJson.locationId}
                        data={wherePlaceSearchingList}
                        virtualized
                        onChange={(e) =>
                          setTempJson((ps) => ({ ...ps, location_ids: e }))
                        }
                        style={{ width: "100%" }}
                        placeholder="Find Location"
                      />
                    </RSFlexboxGrid.Item>
                    <RSFlexboxGrid.Item
                      colspan={12}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      className="mb-3"
                    >
                      <label>Pref Location:</label>
                      <RSSelectPicker
                        value={valueJson.prefLocationId}
                        data={wherePlaceSearchingList}
                        virtualized
                        onChange={(e) =>
                          setTempJson((ps) => ({ ...ps, pref_location_ids: e }))
                        }
                        style={{ width: "100%" }}
                        placeholder="Find Location"
                      />
                    </RSFlexboxGrid.Item>

                    {/* Expectation Salary */}
                    <RSFlexboxGrid.Item
                      colspan={12}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      className="mb-3"
                    >
                      <label>Expectation Salary:</label>
                      <RSSelectPicker
                        value={valueJson.expSalaryType}
                        placeholder="Please Select Expectation Salary"
                        data={salaryRangeList}
                        onChange={(e) =>
                          setTempJson((ps) => ({ ...ps, exp_salary_type: e }))
                        }
                        style={{ width: "100%" }}
                      />
                    </RSFlexboxGrid.Item>

                    {/* Key Skills */}
                    <RSFlexboxGrid.Item
                      colspan={12}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      className="mb-3"
                    >
                      <label>Key Skills:</label>
                      <RSTagPicker
                        data={getKeySkillsList}
                        value={valueJson.skillType}
                        onChange={(e) =>
                          setTempJson((ps) => ({ ...ps, skill_type: e }))
                        }
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        tagProps={{
                          style: {
                            fontWeight: "bold",
                            backgroundColor: "rgb(52 152 254)",
                            color: "#fff",
                            padding: "3px 24px 3px 7px",
                            borderRadius: "5px",
                          },
                        }}
                      />
                    </RSFlexboxGrid.Item>
                  </RSFlexboxGrid>
                </RSCard.Body>

                <RSCard.Footer>
                  <RSButton
                    variant="contained"
                    onClick={handleUpdate}
                    color="primary"
                    size="small"
                  >
                    Update Details
                  </RSButton>
                </RSCard.Footer>
              </RSCard>
            </Box>
          ) : null}
        </>
      )}

      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogContent>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "10px" }}
            >
              <Typography variant="h6">
                Edit Personal Details for {valueJson.name}
              </Typography>
              <Box display="flex" flexDirection="column" mt={2}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  value={getJson.first_name}
                  onChange={handleInputChange}
                  name="first_name"
                  size="small"
                  fullWidth
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  value={getJson.last_name}
                  onChange={handleInputChange}
                  name="last_name"
                  size="small"
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Box>

              {/* <Box sx={{ mt: 2 }}>
                                <TextField
                                    label="Address Location"
                                    multiline
                                    value={getJson.location_name}
                                    onChange={handleInputChange}
                                    name="location_name"
                                    maxRows={4}
                                    fullWidth
                                    size="small"
                                />
                            </Box> */}

              <Box sx={{ mt: 2 }}>
                <PhoneInput
                  country={getJson.country_code || "us"}
                  value={getJson.phone_no}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                    fontSize: "16px",
                  }}
                  containerStyle={{
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary" disabled={loading}>
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
