import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useSalaryTypeList = () => {
  const [salaryTypeList, setSalaryTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalaryTypeList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetSalaryTypeList();
        setSalaryTypeList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch employment types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSalaryTypeList();
  }, []);

  return { salaryTypeList, isLoading, error };
};
