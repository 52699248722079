import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Box,
  Grid,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import axios from "../../api/axios";
import {
  Modal as RSModal,
  HStack,
  CheckPicker,
  TreePicker,
  InputPicker as RSInputPicker,
  InlineEdit as RSInlineEdit,
  VStack,
  Schema,
  Panel,
  FlexboxGrid,
  Form,
  Uploader,
  CheckPicker as RSCheckPicker,
  Drawer as RSDrawer,
  ButtonToolbar as RSButtonToolbar,
  Radio as RSRadio,
  Button as RSButton,
  Input as RSInput,
  CheckboxGroup as RSCheckboxGroup,
  Checkbox as RSCheckbox,
  Toggle as RSToggle,
  Text as RSText,
  RadioGroup as RSRadioGroup,
  SelectPicker as RSSelectPicker,
  CheckTreePicker,
  Toggle,
  // IconButton,
  Stack,
  Whisper,
  Tooltip,
  Placeholder,
  Loader,
} from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

import apiService from "../../api/apiService";
import domain from "../../api/constent";
import Session from "../../api/session";
import { sweetAlert } from "../../utils/sweetAlert";
import GroupedSelect from "../../mui/select/GroupedSelect";

const MyModalRS = NiceModal.create(({ id, name }) => {
  const modal = useModal();
  return (
    <RSModal
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <RSModal.Header>
        <RSModal.Title>Hello React Suite</RSModal.Title>
      </RSModal.Header>
      <RSModal.Body>
        Greetings: {id},{name}!
      </RSModal.Body>
    </RSModal>
  );
});
// Main Component
export default function EducationComp() {
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // Flag to track if editing
  const [formData, setFormData] = useState({
    pid: 0,
    course: "",
    institution: "",
    qualificationComplete: false,
    qualificationType: 0,
    finishedYear: "",
    courseHighlights: "",
  });
  const [errors, setErrors] = useState({
    course: "",
    institution: "",
    courseHighlights: "",
    qualificationType: 0,
  });
  const [lists, setLists] = useState([]);
  const [qualificationList, setQualificaitonList] = useState([]);
  const [loading, setLoading] = useState(true);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, index) => currentYear - index);

  useEffect(() => {
    fetchEducationList();
    qualificationMount();

    // useEducationList();/
  }, []);

  const qualificationMount = async () => {
    // setLoading(false);
    try {
      const data = await apiService.GetEducationQualificationList();
      setQualificaitonList(data);
      // setError(null);
      // console.log(data);
    } catch (err) {
      console.log(err);

      // setError("Failed to fetch employment types");
    } finally {
      // setLoading(true);
    }
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };

  const handleOpenDialog = (data = null) => {
    if (data) {
      setFormData({
        ...data,
        pid: data.id,
        qualificationComplete: data.qualification_complete === 1,
        finishedYear: data.finished_year,
        courseHighlights: data.course_highlights,
        qualificationType: data.qualification_type,
      });
      setIsEditMode(true);
    } else {
      setFormData({
        pid: 0,
        course: "",
        institution: "",
        qualificationComplete: false,
        finishedYear: "",
        courseHighlights: "",
        qualificationType: "",
      });
      setIsEditMode(false);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsEditMode(false);
  };

  const handleSave = async () => {
    const newErrors = {};
    if (!formData.course) newErrors.course = "Course is required";
    if (!formData.institution)
      newErrors.institution = "Institution is required";
    if (!formData.courseHighlights)
      newErrors.courseHighlights = "Course Highlights are required";

    const formDataUpload = {
      registration_id: Session.getDataSessionStore().id,
      course: formData.course,
      institution: formData.institution,
      qualification_complete: formData.qualificationComplete,
      qualification_type: formData.qualificationType,
      finished_year: formData.finishedYear,
      course_highlights: formData.courseHighlights,
    };
    const formDataUpload2 = {
      id: formData.pid,
      registration_id: Session.getDataSessionStore().id,
      course: formData.course,
      institution: formData.institution,
      qualification_complete: formData.qualificationComplete,
      qualification_type: formData.qualificationType,
      finished_year: formData.finishedYear,
      course_highlights: formData.courseHighlights,
    };
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      if (isEditMode) {
        await axios
          .post(
            `${domain.Candidate_Education_Edit}${formDataUpload2.id}`,
            formDataUpload2
          )
          .then((response) => {
            console.log("Updated Education:", response.data);
            setOpenDialog(false);

            sweetAlert(
              "Success!",
              "You Education has been updated successfully.",
              1
            ); // Success
          })
          .catch((error) => {
            console.error("Error updating education:", error);
            sweetAlert("error!", error, 2); // Success
          });
      } else {
        await axios
          .post(domain.Candidate_Education_Create, formDataUpload)
          .then((response) => {
            console.log("Added Education:", response.data);
            setOpenDialog(false);
            sweetAlert(
              "Success!",
              "You Education has been created successfully.",
              1
            ); // Success
          })
          .catch((error) => {
            console.error("Error adding education:", error);
            sweetAlert("error!", error, 2); // Success
          });
      }
      fetchEducationList();
    }
  };
  const getValueById = () => {
    for (const group of qualificationList) {
      const foundChild = group.children.find(
        (child) => child.id === formData.qualificationType
      );
      if (foundChild) {
        return foundChild.value;
      }
    }
    return "";
  };
  const fetchEducationList = async () => {
    // Retrieve session data
    const sessionData = Session.getDataSessionStore();

    // Check if sessionData exists and has required properties
    if (sessionData && sessionData.id && sessionData.email) {
      const { id: userId, email: userEmail } = sessionData;

      // Fetch data from API
      axios
        .get(`${domain.Education_List}${userId}`)
        .then((response) => {
          setLists(response.data.data); // Set the retrieved data
          setLoading(false); // Stop loading
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Stop loading on error
        });
    } else {
      console.error("Session data is missing or incomplete");
      setLoading(false); // Stop loading if session data is invalid
    }
  };

  const styles = {
    card: {
      border: "1px solid #e0e0e0",
      borderRadius: "8px",
      padding: "16px",
      marginTop: "5px",
      maxWidth: "400px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      fontFamily: "Arial, sans-serif",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    course: {
      fontSize: "18px",
      fontWeight: "bold",
      margin: 0,
    },
    editButton: {
      background: "none",
      border: "none",
      cursor: "pointer",
      fontSize: "16px",
    },
    verifyLink: {
      color: "#007bff",
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "500",
    },
    institution: {
      fontSize: "16px",
      color: "#333",
      marginTop: "8px",
    },
    finishedYear: {
      fontSize: "14px",
      color: "#666",
      marginTop: "4px",
    },
    highlights: {
      fontSize: "14px",
      color: "#666",
      marginTop: "4px",
    },
  };

  return (
    <div>
      <strong>Education</strong>
      <p>Tell Employers about your education</p>
      {loading ? (
        <ShimmerCategoryItems mode="light" /> // Show shimmer effect while loading
      ) : (
        lists.map((item) => (
          <div style={styles.card} key={item.id}>
            <div style={styles.header}>
              <h3 style={styles.course}>{item.course}</h3>
              <button
                style={styles.editButton}
                onClick={() => handleOpenDialog(item)}
                title="Edit"
              >
                ✏️
              </button>
            </div>
            <a href="#" style={styles.verifyLink}>
              {item.institution}
            </a>
            <p style={styles.institution}>{item.institution}</p>
            <p style={styles.finishedYear}>Finished {item.finished_year}</p>
            <p style={styles.highlights}>{item.course_highlights}</p>
          </div>
        ))
      )}
      <div className="pt-2">
        <Button
          size="small"
          variant="contained"
          onClick={() => handleOpenDialog()}
        >
          Add Education
        </Button>
      </div>
      {/* <HStack>
        <Button
          appearance="primary"
          onClick={() => {
            NiceModal.show(MyModalRS, { id: "test", name: "Nate" });
          }}
        >
          Show Modal
        </Button>

        <Button
          appearance="primary"
          onClick={() => {
            NiceModal.show(MyModalRS, { id: "test", name: "Nate" });
          }}
        >
          Show Drawer
        </Button>
      </HStack> */}
      {/* Education Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <strong>{isEditMode ? "Edit Education" : "Add Education"}</strong>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Course */}
            <Grid item xs={12}>
              <TextField
                label="Course or Qualification"
                variant="outlined"
                fullWidth
                value={formData.course}
                onChange={(e) => handleChange("course", e.target.value)}
                error={!!errors.course}
                helperText={errors.course}
                sx={{ marginTop: 3 }}
              />
            </Grid>

            {/* Institution */}
            <Grid item xs={12}>
              <TextField
                label="Institution"
                variant="outlined"
                fullWidth
                value={formData.institution}
                onChange={(e) => handleChange("institution", e.target.value)}
                error={!!errors.institution}
                helperText={errors.institution}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel htmlFor="grouped-native-select">
                  Grouping
                </InputLabel>
                <Select
                  value={formData.qualificationType}
                  native
                  onChange={(e) =>
                    handleChange("qualificationType", e.target.value)
                  }
                  // defaultValue={0}
                  id="grouped-native-select"
                  label="Grouping"
                >
                  <option aria-label="None" value="" />
                  {qualificationList.map((group) => (
                    <optgroup key={group.id} label={group.label}>
                      {group.children.map((child) => (
                        <option key={child.id} value={child.id}>
                          {child.label}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel>Qualification List</InputLabel>
                <Select
                  value={formData.finishedYear}
                  onChange={(e) => handleChange("finishedYear", e.target.value)}
                  label="Finished Year"
                  error={!!errors.finishedYear}
                >
                  {qualificationList.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>

            {/* Qualification Complete (Checkbox) */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.qualificationComplete}
                    onChange={(e) =>
                      handleChange("qualificationComplete", e.target.checked)
                    }
                  />
                }
                label="Qualification Complete"
              />
            </Grid>

            {/* Finished Year Dropdown */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Finished Year</InputLabel>
                <Select
                  value={formData.finishedYear}
                  onChange={(e) => handleChange("finishedYear", e.target.value)}
                  label="Finished Year"
                  error={!!errors.finishedYear}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Course Highlights */}
            <Grid item xs={12}>
              <TextField
                label="Course Highlights"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={formData.courseHighlights}
                onChange={(e) =>
                  handleChange("courseHighlights", e.target.value)
                }
                error={!!errors.courseHighlights}
                helperText={errors.courseHighlights}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
