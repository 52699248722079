import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useNotificationFreqList = () => {
  const [notificationFrequencyLists, setNotificationFrequencyLists] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotificationFreqList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetNotificationList();
        setNotificationFrequencyLists(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch CompanyIndustriesList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotificationFreqList();
  }, []);

  return { notificationFrequencyLists, isLoading, error };
};
