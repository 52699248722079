import React from "react";
import { Box, Link, Typography } from "@mui/material";
const Footer = () => {
  return (
    <Box sx={{ p: 3, textAlign: "center" }}>
      {/* https://www.wrappixel.com */}
      <Typography>
        © 2022 All rights reserved by <Link href="/"> DT Shelf Pty Ltd.</Link>{" "}
      </Typography>
    </Box>
  );
};

export default Footer;
