import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useCompanySizeList = () => {
  const [companySizeList, setCompanySizeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCompanySizeList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetCompanySizeList();
        setCompanySizeList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch GetCompanySizeList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanySizeList();
  }, []);

  return { companySizeList, isLoading, error };
};
