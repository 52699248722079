import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown";

function SortBuy({ prop, props, mountSetPerPage, mountSetSortList }) {
  const [perPage, setPerPage] = useState(prop[0]?.value || ""); // Default to the first option
  const [sortList, setSortList] = useState(props[0]?.value || ""); // Default to the first option

  // Propagate changes to parent whenever perPage or sortList updates
  useEffect(() => {
    mountSetPerPage(perPage);
    mountSetSortList(sortList);
  }, [perPage, sortList]);

  // Handlers for dropdown changes
  const handlePerPageChange = (selectedOption) => {
    setPerPage(selectedOption.value);
  };

  const handleSortListChange = (selectedOption) => {
    setSortList(selectedOption.value);
  };

  return (
    <div className="group-select">
      {/* Per Page Dropdown */}
      <Dropdown
        options={prop}
        className="react-dropdown sort-buy"
        value={prop.find((option) => option.value === perPage)} // Sync with state
        onChange={handlePerPageChange} // Update state on change
        placeholder="Select Per Page"
      />

      {/* Sort By Dropdown */}
      <Dropdown
        options={props}
        className="react-dropdown sort-buy"
        value={props.find((option) => option.value === sortList)} // Sync with state
        onChange={handleSortListChange} // Update state on change
        placeholder="Select Sort Option"
      />
    </div>
  );
}

// Prop validation
SortBuy.propTypes = {
  prop: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  props: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  mountSetPerPage: PropTypes.func.isRequired,
  mountSetSortList: PropTypes.func.isRequired,
};

export default SortBuy;
