import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import SwitchCameraOutlinedIcon from "@mui/icons-material/SwitchCameraOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import Work from "@mui/icons-material/Work";
import { ManageHistory } from "@mui/icons-material";

const Menuitems = [
  {
    title: "Dashboard",
    icon: DashboardOutlinedIcon,
    href: "/",
  },
  {
    title: "Jobs",
    icon: Work,
    items: [
      {
        title: "Post Vacancy",
        href: "/post-vacancy",
      },
      {
        title: "Manage Job Post",
        href: "manage-job-post",
      },
      // {
      //   title: "Manage Job candidate",
      //   href: "manage-job-candidate-list",
      // },
    ],
  },
  {
    title: "Forms",
    icon: AddToPhotosOutlinedIcon,
    items: [
      {
        title: "Autocomplete",
        href: "/form-elements/autocomplete",
      },
      {
        title: "Buttons",
        href: "/form-elements/button",
      },
      {
        title: "Checkbox",
        href: "/form-elements/checkbox",
      },
    ],
  },
  {
    title: "Table",
    icon: AutoAwesomeMosaicOutlinedIcon,
    href: "/tables/basic-table",
  },
];

export default Menuitems;
