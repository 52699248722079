import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css"; // Ensure the dropdown styles are imported

function SelectLocation({ prop }) {
  return (
    <Dropdown
      options={prop} // Use the prop array directly
      className="react-dropdown select-location"
      value={prop[0]} // Set the default value to the first option
      onChange={(selected) => console.log("Selected:", selected)} // Handle selection
    />
  );
}

export default SelectLocation;
