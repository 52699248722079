import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import FullLayout from "../../Employer/layouts/FullLayout/FullLayout.js";
import Dashboard1 from "../../Employer/views/dashboards/Dashboard1.js";
import ExAutoComplete from "../../Employer/views/FormElements/ExAutoComplete.js";
import ExButton from "../../Employer/views/FormElements/ExButton.js";
import BasicTable from "../../Employer/views/tables/BasicTable.js";
import ExCheckbox from "../../Employer/views/FormElements/ExCheckbox.js";
import ExRadio from "../../Employer/views/FormElements/ExRadio.js";
import ExSlider from "../../Employer/views/FormElements/ExSlider.js";
import ExSwitch from "../../Employer/views/FormElements/ExSwitch.js";
import FormLayouts from "../../Employer/views/FormLayouts/FormLayouts.js";
import ApplyJobVacancy from "../../Employer/views/post-vacancy/PostJobVacancy.jsx";
import PostJobPreview from "../../Employer/views/post-vacancy/PostJobPreview.jsx";
import ManageJobPost from "../../Employer/components/ManageJobPost/ManageJobPost.jsx";
import ManageJobCandidateList from "../../Employer/components/ManageJobPost/ManageCandidate/ManagejobCandidateList.jsx";
import Page404 from "../../pages/Page404.jsx";
import ManageJobPostEdit from "../../Employer/components/ManageJobPost/Edit/ManageJobPostEdit.jsx";
import ManageJobPostEditPreviewSubmit from "../../Employer/components/ManageJobPost/Edit/ManageJobPostEditPreviewSubmit.jsx";
import ManageCandidatePreview from "../../Employer/components/ManageJobPost/PreviewCandidate.jsx/ManageCandidatePreview.jsx";
// Define the ThemeRoutes array
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "dashboard", element: <Dashboard1 /> },
      { path: "post-vacancy", element: <ApplyJobVacancy /> },
      { path: "post-review", element: <PostJobPreview /> },
      {
        path: "manage-job-candidate-list",
        element: <ManageJobCandidateList />,
      },
      { path: "manage-job-post-edit", element: <ManageJobPostEdit /> },
      {
        path: "manage-job-post-edit-preview-submit",
        element: <ManageJobPostEditPreviewSubmit />,
      },
      { path: "manage-job-post", element: <ManageJobPost /> },
      { path: "manage-candidate-preview", element: <ManageCandidatePreview /> },
      { path: "tables/basic-table", element: <BasicTable /> },
      { path: "form-layouts", element: <FormLayouts /> },
      {
        path: "form-elements",
        children: [
          { path: "autocomplete", element: <ExAutoComplete /> },
          { path: "button", element: <ExButton /> },
          { path: "checkbox", element: <ExCheckbox /> },
          { path: "radio", element: <ExRadio /> },
          { path: "slider", element: <ExSlider /> },
          { path: "switch", element: <ExSwitch /> },
        ],
      },
      { path: "*", element: <Page404 /> }, //<Navigate to="/" replace /> },
    ],
  },
  // Catch-all route for unmatched paths
];

// AppEmployerRoutePath uses ThemeRoutes with `useRoutes`
const AppEmployerRoutePath = () => {
  const routes = useRoutes(ThemeRoutes);
  return routes;
};

export default AppEmployerRoutePath;
