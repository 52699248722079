import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useCompanyIndustriesList = () => {
  const [companyIndustriesList, setCompanyIndustriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWComapnyIndusListList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetCompanyIndustryList();
        setCompanyIndustriesList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch CompanyIndustriesList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWComapnyIndusListList();
  }, []);

  return { companyIndustriesList, isLoading, error };
};
