import Swal from "sweetalert2";

// import { useWindowSize } from "react-use";
// import ReactConfetti from "react-confetti";
import Confetti from 'react-confetti'
export const sweetAlert = (title, text, type = 1, timer = 2000) => {
  let icon = "error"; // Default icon for error

  if (type === 1) {
    icon = "success"; // Success
  } else if (type === 2) {
    icon = "error"; // Error
  } else if (type === 3) {
    icon = "warning"; // Warning
  }

  Swal.fire({
    title,
    text,
    icon,
    position: "center",
    showConfirmButton: false,
    timer,
    toast: true,
  });
};

export const CelebrateAlert = () => {
  // const { width, height } = useWindowSize();
  // console.log("SDddd");
  
  return (
    <div
      style={{
        minHeight: 200,
      }}
    >
      <Confetti
        gravity={0.1}
        height={841}
        initialVelocityX={2}
        initialVelocityY={2}
        numberOfPieces={200}
        opacity={1}
        recycle
        run
        width={1380}
        wind={0}
      />
    </div>
  );
};
