import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useSalaryRangeList = () => {
  const [salaryRangeList, setSalaryRangeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalaryRangeList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetSalaryRangeList();
        setSalaryRangeList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch salaryProcessTypeList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSalaryRangeList();
  }, []);

  return { salaryRangeList, isLoading, error };
};
