import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useWorkingExpList = () => {
  const [workExpList, setWorkExpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWorkExpList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetWorkExpList();
        setWorkExpList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch workExpList types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkExpList();
  }, []);

  return { workExpList, isLoading, error };
};
