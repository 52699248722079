import { React, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import LoaderMount from "../components/preloader";
import Gotop from "../components/gotop";
import { Modal, Drawer, Button, HStack } from 'rsuite';
import NiceModal from '@ebay/nice-modal-react';
// layout
import Footer from "./subCandidate/Footer"; //footer
import AppRoutePath from "./subCandidate/AppRoutePath"; //router
import Header from "./subCandidate/Header"; //header

const AppCandidate = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [false]);

  return (
    <>
      {!loading ? (
        <BrowserRouter future={{ v7_startTransition: true }}>
          <Header />
          <ScrollToTop />
          <NiceModal.Provider>
            {/* <HStack> */}
              <AppRoutePath />
            {/* </HStack> */}
          </NiceModal.Provider>
          <Footer />
          <Gotop />
        </BrowserRouter>
      ) : (
        <LoaderMount.Preloader />
      )}
    </>
  );
};

export default AppCandidate;
