import { React, Suspense, useState, useEffect } from "react";
import { Routes, Route, Link, useRoutes, HashRouter } from "react-router-dom";

import ScrollToTop from "../ScrollToTop";
import Preloader from "../components/preloader";
import Gotop from "../components/gotop";

import AppEmployerRoutePath from "./subEmployer/AppEmployerRoutePath";
import Footer from "./subEmployer/Footer";
import Header from "./subEmployer/Header";

import Spinner from "../Employer/views/Spinner/Spinner";
// import { CustomProvider } from "rsuite";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";
import { baseTheme } from "../Employer/assets/global/Theme-variable"; //" './assets/global/Theme-variable';
const AppEmployer = () => {
  const theme = baseTheme;

  return (
    <Suspense fallback={<Spinner />}>
      <HashRouter
        future={{
          v7_startTransition: true,
        }}
      >
        <ThemeProvider theme={theme}>
          <AppEmployerRoutePath />
        </ThemeProvider>
      </HashRouter>
    </Suspense>
  );
};

export default AppEmployer;
