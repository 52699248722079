import { useEffect, useCallback, useRef } from 'react';

export const useResizeObserver = (target = null, options = {}) => {
  const observerRef = useRef(null);

  const handleError = useCallback((error) => {
    // Only handle ResizeObserver errors
    if (error?.message?.includes('ResizeObserver')) {
      error.preventDefault();
      error.stopPropagation();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleError, true);
    
    let rafId = null;
    observerRef.current = new ResizeObserver((entries) => {
      // Cancel any pending rAF
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
      
      // Schedule new observation
      rafId = requestAnimationFrame(() => {
        if (options.onResize) {
          options.onResize(entries);
        }
      });
    });

    // Observe target if provided
    if (target?.current) {
      observerRef.current.observe(target.current);
    }

    return () => {
      window.removeEventListener('error', handleError, true);
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [handleError, target, options.onResize]);

  return observerRef.current;
};