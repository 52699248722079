import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useRoleList = (departmentCategory) => {
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!departmentCategory) {
      setRoleList([]);
      return;
    }

    const fetchRoleList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetRoleList(departmentCategory);
        setRoleList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch role list");
        setRoleList([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRoleList();
  }, [departmentCategory]);

  return { roleList, isLoading, error };
};
