import { useState, useEffect } from "react";
import apiService from "../../../api/apiService";

export const useEducationList = () => {
  const [educationOptionsList, setEducationOptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEducationList = async () => {
      setIsLoading(true);
      try {
        const data = await apiService.GetEducationQualificationList();
        setEducationOptionsList(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch employment types");
      } finally {
        setIsLoading(false);
      }
    };

    fetchEducationList();
  }, []);

  return { educationOptionsList, isLoading, error };
};
